<template>
  <div style="overflow: scroll; margin-bottom: 160px;" id="rfq-view-table"
       class="repairer-rfq-view-part-list-container">
    <b-table-simple table-class="ps-scroll repairer-rfq-view-part-list-table-container-2" id="rfq-view-table-2"
                    :class="{'rfq-received': status ==='Received'}">
      <b-thead id="rfq-view-thead">
        <b-tr>
          <b-th id="rfq-view-line-column" class="part-list-line align-top"
                :style="{left: getLeftPosition('rfq-view-line-column')}">
            <div>Line</div>
          </b-th>
          <b-th id="rfq-view-part-list-column" class="part-list-part-name align-top"
                :style="{left: getLeftPosition('rfq-view-part-list-column')}">
            <div>Parts List</div>
            <span v-on:click="supplierPrice.sortBy('name')"></span>
          </b-th>
          <b-th id="rfq-view-part-number-column" class="part-list-number text-center align-top"
                :style="{left: getLeftPosition('rfq-view-part-number-column')}">
            <div class="d-flex justify-content-center">
              <div class="div-number">Part Number</div>
              <span class="ml-2" v-on:click="supplierPrice.sortBy('number')"></span>
            </div>
          </b-th>
          <b-th id="rfq-view-qty-column" class="part-list-qty text-center align-top"
                :style="{left: getLeftPosition('rfq-view-qty-column')}">
            <div>
              <div class="div-qty">Qty</div>
              <span v-on:click="supplierPrice.sortBy('qty')"></span>
            </div>
          </b-th>
          <b-th
              id="rfq-view-rrp-column"
              :style="{left: getLeftPosition('rfq-view-rrp-column')}"
              v-if="isExistListColumn && ((position === 2 || position === 3 || position === 4) || (position===2 && status==='Cancelled'))"
              class="part-list-rrp text-center align-top">
            <div class="d-flex justify-content-center">
              <div class="div-qty">List</div>
              <span v-on:click="supplierPrice.sortBy('rrp')"></span>
            </div>
          </b-th>
          <b-th
              v-for="supplier in supplierPrice.orderedSuppliers"
              :key="supplier.id"
              :class="{
                'selected-col': !supplier.isRfqToSupplierStatusDecline && ((supplierPrice.selectedSupplierId === supplier.id && supplierPrice.orderedSuppliers.length > 1)|| isSelectedAllParts(supplier)) && isClickable,
                'supplier-column-highlight': isActiveColumn(supplier.id)
              }"
              @click="supplierClicked(supplier)"
              @mouseover="setActiveSupplier(supplier.id)"
              @contextmenu.prevent="openContextMenu(supplier, $event)"
              @mouseleave="setActiveSupplier(null)"
              class="text-center align-top clickable"
              :id="'rfq-view-supplier-' + supplier.id"
              style="max-width: 135px; position: relative; z-index: 1;">
            <div
                v-b-tooltip.hover="{
                                customClass: 'ps-tooltip',
                                html: true,
                                title:  isBlinkingGreen(-1) && (supplier.type === 'OEM-GEN' || supplier.type === 'OEM/GEN') ? supplier.name + ' has submitted Part Nbrs & List' : `Quote NBR - ${supplier.dmsQuoteNumber}`,
                                placement:'leftbottom',
                                disabled: isBlinkingGreen(-1) && (supplier.type === 'OEM-GEN' || supplier.type === 'OEM/GEN') ? false : supplier.dmsQuoteNumber == null
                            }"
                :class="{'blinking-green': isBlinkingGreen(-1) && (supplier.type === 'OEM-GEN' || supplier.type === 'OEM/GEN')}"
                @click="unBlinkPart(-1)"
                class="supplier-name text-center"
            >
              {{ supplier.name }}
              <span v-if="supplier.isNonRegistered" style="color: red">
                <br/> Non-Registered
              </span>
            </div>
            <div class="supplier-icon text-center">
              <img v-if="supplier.logo" :src="supplier.logo">
              <template v-if="isRFQPriced && ((supplier.secondRequestBestPrice == true || supplier.secondRequestBestPrice == false) || (supplier.thirdRequestBestPrice == true || supplier.thirdRequestBestPrice == false))">
                <div class="d-flex" style="justify-content: center">
                  <i
                      v-if="isLockedSupplier(supplier)"
                      class='bx bx-lock-alt' style="color: #bd5008;"
                      v-b-tooltip.hover="{
                    customClass: 'ps-tooltip supplier-icon-ps-tooltip',
                    html: true,
                    title: 'This suppliers pricing is locked in.',
                    placement:'righttop',
                  }"></i>
                  <i
                      v-if="supplier.isNonRegistered"
                      class='bx bx-error' style="color: #bd5008;"></i>
                  <i
                      v-if="supplier.isNonRegistered && supplier.pdfFile && supplier.pdfFile.src"
                      @click="$emit('selectedPdf', supplier.pdfFile.src)"
                      class='bx bxs-file-pdf'
                      v-b-tooltip.hover="{
                        customClass: 'ps-tooltip supplier-icon-ps-tooltip',
                        html: true,
                        title: 'View PDF',
                        placement:'righttop',
                      }"
                      style="color: #bd5008;"></i>
                  <i
                      v-if="isUnLockedSupplier(supplier)"
                      class='bx bx-lock-open-alt'
                      v-b-tooltip.hover="{
                        customClass: 'ps-tooltip supplier-icon-ps-tooltip',
                        html: true,
                        title: 'Price change unlocked',
                        placement:'righttop',
                      }"
                      style="color: #4b8823;"
                  ></i>
                </div>
              </template>
            </div>
            <div v-if="supplier.lowestTotalPrice && isClickable && 1==-1"
                 class="supplier-type text-center justify-content-center theme-color">
              BEST VALUE
            </div>

            <div class="supplier-type text-center">
              {{ supplier.type }}
            </div>
            <div class="part-list-company-color" :style="{ background: getSupplierColor(supplier) }"></div>
          </b-th>
          <b-th v-if="status === 'Received'" class="part-list-action text-center align-top" id="rfq-view-action-column">
            <div>
              <div class="div-action">Action</div>
            </div>
          </b-th>
        </b-tr>
      </b-thead>
      <b-tbody>
        <b-tr v-if="(isRFQPriced || isRFQCancelled) && position !== 2">
          <b-td class="subheader subheader-p-c info-line" :colspan="isExistListColumn?5:4"
                :style="{left: getLeftPosition('info-line')}">Supplier Quote Nbr<span
              class="subheader-border-right"></span></b-td>
          <b-td class="p-c-value text-center"
                style="position: relative; z-index: 1;"
                :class="{'supplier-column-highlight': isActiveColumn(supplier.id)}"
                v-for="supplier in supplierPrice.orderedSuppliers" :key="supplier.id">
            {{ supplier.supplierQuoteNumber }}
          </b-td>
        </b-tr>
        <b-tr v-if="position!==1">
          <b-td class="subheader subheader-p-c info-line" :colspan="isExistListColumn?5:4"
                :style="{left: getLeftPosition('info-line')}">Parts In Stock Today<span
              class="subheader-border-right"></span></b-td>
          <b-td class="p-c-value text-center"
                style="position: relative; z-index: 1;"
                :class="{'supplier-column-highlight': isActiveColumn(supplier.id)}"
                v-for="supplier in supplierPrice.orderedSuppliers" :key="supplier.id">
            {{ supplier.inStock }}/{{ supplier.countPartForInStock }}
          </b-td>
        </b-tr>

        <b-tr class="rfq-view-part-line" v-for="(part, index) in allParts" :key="part.id">
          <b-td class="part-list-line rfq-view-line-cell" :style="{left: getLeftPosition('rfq-view-line-column')}">
            <div class="part-list-line-number">
              {{ index + 1 }}
            </div>
          </b-td>
          <b-td class="part-list-part-name rfq-view-part-list-cell"
                :style="{left: getLeftPosition('rfq-view-part-list-column')}"
                :class="{ 'color-grey': !supplierPrice.isPartSelected(part.id),
                                  'part-list-part-name-submitted': status==='Submitted' || status==='Priced',
                                  'linked': partIsLinked(part),
                                  'has-alt': part.altPartNames.length > 0 || (part.nameBySupplier != '' && status==='Submitted')}">
            <!--                    <h1>{{part.altPartNames.length}}</h1>-->
            <div class="part-list-part-name__group">
              <input v-if="part.isEdit" v-model="part.name" class="form-control"/>
              <div v-else-if="!part.isRemovedByRepairer"
                   :class="{'part-list-item-alt-name': isPartNameGray(part), 'part-list-item-name-original-original-linked': part.isOriginalPart && partIsLinked(part)}"
                   class="part-list-item-name">
                {{ part.name }}
              </div>
              <div v-else class="part-list-item-name"
                   :class="{'part-list-item-alt-name': isPartNameGray(part), 'linked': partIsLinked(part)}"
                   style="color: #afafaf">
                {{ part.name }}
                (Removed By Repairer)
              </div>
              <div class="part-list-item-alt-name" :class="{'linked': partIsLinked(part)}"
                   v-if="isRFQSubmitted && getPartNameForSubmitted(part.id)">
                {{ getPartNameForSubmitted(part.id) }}
              </div>

              <div v-if="getDeclineReasonForSubmitted()" class="part-list-item-alt-name error-text"
                   style="color: #ff5e5e; opacity: 1">
                {{ getDeclineReasonForSubmitted() }}
              </div>
              <!--                      <div v-if="part.nameBySupplier != '' && toLower(part.name) != toLower(part.nameBySupplier) && status==='Submitted'" class="part-list-item-alt-name"> {{ part.nameBySupplier }}</div>-->
              <template v-if="partIsLinked(part) || part.altPartNames.length > 0">
                <!--                <div v-for="(altPartName, index) in part.altPartNames" :key="index"-->
                <!--                    class="part-list-item-alt-name">-->
                <!--                  {{ altPartName }}-->
                <!--                </div>-->
                <div :class="{'linked': partIsLinked(part)}" class="part-list-item-alt-name">
                  {{ getAltPartName(part.id) }}
                </div>


                <!-- <div class="part-list-item-alt-name">{{ part.linkedName || getAltPartName(part.id) }}</div>-->
              </template>
            </div>

          </b-td>
          <b-td class="part-list-number text-center rfq-view-part-number-cell"
                :style="{left: getLeftPosition('rfq-view-part-number-column')}"
                style="padding-left: 0px; padding-right: 0px;">
            <input v-if="part.isEdit" v-model="part.number" class="form-control"/>
            <template v-else-if="isRFQSubmitted && getPartNumberForSubmitted(part.id)">
              <span
                  :class="{'blinking-green': isBlinkingGreen(part.id)}"
                  @click="unBlinkPart(part.id)"
                  v-b-tooltip.hover="{
                    customClass: 'ps-tooltip',
                    html: true,
                    title:  OEMSupplier.name + ' has submitted Part Nbrs & List',
                    placement:'leftbottom',
                    disabled: !isBlinkingGreen(part.id)
                  }"
              >
                {{ getPartNumberForSubmitted(part.id) }}
              </span>
            </template>
            <template v-else>
              <div
                  v-if="part.number === getPartNumber(part.id) || isEmpty(getPartNumber(part.id))"
                  :class="{'blinking-green': isBlinkingGreen(part.id)}"
                  @click="unBlinkPart(part.id)"
                  v-b-tooltip.hover="{
                    customClass: 'ps-tooltip',
                    html: true,
                    title:  OEMSupplier.name + ' has submitted Part Nbrs & List',
                    placement:'leftbottom',
                    disabled: !isBlinkingGreen(part.id)
                  }"
                  class="part-list-item-number">
                <span
                    :class="{'font-weight-bold': getPartNumberOfSelectedPart(part.id) !== part.number}">{{ part.number }}</span>
                <template v-if="getPartNumberOfSelectedPart(part.id) !== part.number">
                  <br/>
                  <span style="font-weight: bold; opacity: 0.5">{{ getPartNumberOfSelectedPart(part.id) }}</span>
                </template>
              </div>
              <div
                  v-else
                  :class="{'blinking-green': isBlinkingGreen(part.id)}"
                  @click="unBlinkPart(part.id)"
                  v-b-tooltip.hover="{
                    customClass: 'ps-tooltip',
                    html: true,
                    title:  OEMSupplier.name + ' has submitted Part Nbrs & List',
                    placement:'leftbottom',
                    disabled: !isBlinkingGreen(part.id)
                  }"
                  class="part-list-item-number">
                <span
                    :class="{'font-weight-bold': getPartNumberOfSelectedPart(part.id) !== getPartNumber(part.id)}">{{ getPartNumber(part.id) }}</span>
                <template v-if="getPartNumberOfSelectedPart(part.id) !== getPartNumber(part.id)">
                  <br/>
                  <span style="font-weight: bold; opacity: 0.5">{{ getPartNumberOfSelectedPart(part.id) }}</span>
                </template>
              </div>
              <div v-if="isRFQPriced" class="">
                <div
                    v-b-tooltip.hover="{customClass: 'ps-tooltip custom-tooltip-position', html: true, title: getStockCommentFull(part.id), placement:'bottom'}"
                    :class="{
                     'part-number-stock-orange': getIsHasExOrBackComment(part.id).ex,
                     'part-number-stock-red': getIsHasExOrBackComment(part.id).back,
                    }"
                >
                  <template v-if="getIsHasExOrBackComment(part.id).ex || getIsHasExOrBackComment(part.id).back">
                    {{ getStockCommentForBackOrEx(part.id) }}
                  </template>
                  <template v-else>
                    <span class="color-gray">{{ getStockComment(part.id) }}</span>

                  </template>
                </div>
              </div>
            </template>
          </b-td>
          <b-td class="part-list-qty text-center rfq-view-qty-cell"
                :class="{'color-grey': !supplierPrice.isPartSelected(part.id)}"
                :style="{left: getLeftPosition('rfq-view-qty-column')}">
            <div v-if="!partIsWithNoCost(part)"
                 class="part-list-item-qty d-flex align-items-center justify-content-center"
                 :class="{'part-list-item-qty-ordered': status==='Ordered' }">
              <!--                        <i v-if="!isEmpty(OEMSupplier) && OEMSupplier.getOffer(part) && part.isParentPart && OEMSupplier.parentRfqToSupplierStatus === 'Won'" class='bx bxs-check-circle check-icon'-->
              <!--                           v-b-tooltip.hover="{customClass: 'ps-tooltip ps-tooltop&#45;&#45;table qty-tooltip', html: true, title: 'Ordered' + `<br>` + '00:00am || dd/mm/yyyy', placement:'leftbottom'}"-->
              <!--                        ></i>-->
              <i v-if="isOffer(part)" class='bx bxs-check-circle check-icon'
                 v-b-tooltip.hover="{customClass: 'ps-tooltip ps-tooltop--table qty-tooltip', html: true, title: getTooltipForOrderedPart(part), placement:'leftbottom'}"
              ></i>
              <i v-if="status==='Ordered'" class='bx bxs-check-circle check-icon'></i>
              <number-formatter ref="mup"
                                v-if="part.isEdit"
                                type="text"
                                v-model="part.qty"
                                @change="part.originalQty = part.qty"
                                class="form-control numeric"
                                format="0,0"></number-formatter>
              <span v-else>{{ part.qty }}</span>
              <i v-if="part.originalQty - part.qty < 0 && !isRFQReceived"
                 v-b-tooltip.hover="{customClass: 'ps-tooltip ps-tooltop--table qty-tooltip', html: true, title: 'Qty Changed', placement:'leftbottom'}"
                 class="bx bx-up-arrow-alt qty-icon-arrow-up qty-icon-arrow">
              </i>
              <i v-else-if="part.originalQty - part.qty > 0 && !isRFQReceived"
                 v-b-tooltip.hover="{customClass: 'ps-tooltip ps-tooltop--table qty-tooltip', html: true, title: 'Qty Changed', placement:'leftbottom'}"
                 class="bx bx-down-arrow-alt qty-icon-arrow-down qty-icon-arrow">
              </i>
            </div>
          </b-td>
          <b-td
              v-if="isExistListColumn && ((position === 2 || position === 3 || position === 4) || (position===2 && status==='Cancelled'))"
              :style="{left: getLeftPosition('rfq-view-rrp-column')}"
              class="part-list-rrp text-center rfq-view-rrp-cell">
            <div v-if="partIsWithNoCost(part)" class="">

            </div>
            <div
                v-else-if="isRFQSubmitted && getPartRrpForSubmitted(part.id)"
                :class="{'blinking-green': isBlinkingGreen(part.id)}"
                @click="unBlinkPart(part.id)"
                v-b-tooltip.hover="{
                    customClass: 'ps-tooltip',
                    html: true,
                    title:  OEMSupplier.name + ' has submitted Part Nbrs & List',
                    placement:'leftbottom',
                    disabled: !isBlinkingGreen(part.id)
                  }">
              {{ getPartRrpForSubmitted(part.id) | formatMoney }}
            </div>
            <div v-else-if="part.isOriginalPart || !part.isDelivery" :class="{
                              'green-cell': (!isEmpty(OEMSupplier) && OEMSupplier.getOffer(part).firstRrp > 0 && OEMSupplier.getOffer(part).firstRrp<part.rrp),
                              'red-cell':(!isEmpty(OEMSupplier) && OEMSupplier.getOffer(part).firstRrp > 0 && OEMSupplier.getOffer(part).firstRrp>part.rrp),
                              'blinking-green': isBlinkingGreen(part.id),
                          }"
                 class="part-list-item-rrp"
                 @click="unBlinkPart(part.id)"
                 v-b-tooltip.hover="{
                    customClass: 'ps-tooltip',
                    html: true,
                    title:  OEMSupplier.name + ' has submitted Part Nbrs & List',
                    placement:'leftbottom',
                    disabled: !isBlinkingGreen(part.id)
                  }"> {{ part.rrp ? part.rrp : '' | formatMoney }}
            </div>
          </b-td>

          <!-- Supplier's Prices -->
          <b-td v-for="(supplier, index) in supplierPrice.orderedSuppliers"
                :key="supplier.id"
                class="text-center"
                @click="priceClicked(supplier, part)"
                style="padding: 0px 15px;"
                :class="{ 'offer-checked' : (!supplier.isRfqToSupplierStatusDecline && part.id && supplier.isPartSelected(part.id) && position > 2),
                                  'part-list-supplier': !supplier.isRfqToSupplierStatusDecline && isClickable && isOfferClickable(supplier, part),
                                  'disable-part': (supplier.isRfqToSupplierStatusDecline || supplier.rfqToSupplierStatus !== 'Priced' && (status === 'Priced' || status==='Ordered' || status === 'Cancelled')) || part.isDelivery || (!part.isOriginalPart && (partIsWithNoCost(part))),
                                  'selected-disable-part': (supplier.isRfqToSupplierStatusDecline || part.isDelivery || (!part.isOriginalPart && (partIsWithNoCost(part)))) && (part.id && supplier.isPartSelected(part.id) && position > 2),
                                  'part-list-supplier-without-border': !isClickable || !isOfferClickable(supplier, part),
                                  'part-list-supplier-non-non-active': part.isParentPart || part.isAdditional,
                                  'supplier-column-highlight': isActiveColumn(supplier.id),
                                  'dark-grey-or-green-triangle': isDarkGreyTriangle(supplier.getOffer(part), supplier) || isGreenTriangle(supplier.getOffer(part), supplier),
                                  'table-b-table-supplier-width': status === 'Submitted' || status === 'Cancelled'}">
            <!-- TODO: move to new component -->
            <template v-if="supplier.isRfqToSupplierStatusDecline">
              <div class=" font-12 decline-color" v-if="isDirectOrder">
                Declnd
              </div>
            </template>
            <!-- ORIGINAL PART -->
            <template v-else-if="part.isOriginalPart">
              <div v-if="supplier.rfqToSupplierStatus === 'Won' && status === 'Ordered' && partIsLinked(part)"
                   class="part-list-item-price font-12"
                   :class="{'part-list-item-price--priced':supplier.type !== 'OEM-GEN' && supplier.type !== 'OEM-PAR'}">
                <template v-if="supplier.type === 'OEM-GEN' && supplier.type !== 'OEM-PAR'">
                  Linked
                </template>
                <template>
                  <template v-if="supplier.getOffer(part).price > 0">
                    {{ supplier.getOffer(part).price | formatMoney }}
                  </template>
                </template>
              </div>
              <div v-else-if="supplier.rfqToSupplierStatus === 'Lost' && status === 'Ordered' && partIsLinked(part)"
                   class="part-list-item-price font-12"
                   :class="{'part-list-item-price--priced':supplier.type !== 'OEM-GEN' && supplier.type !== 'OEM-PAR'}">
                <template v-if="supplier.type === 'OEM-GEN' || supplier.type === 'OEM-PAR'">
                  Linked
                </template>
                <template v-else-if="supplier.getOffer(part).price > 0">
                  {{ supplier.getOffer(part).price | formatMoney }}
                </template>
              </div>
              <div v-else-if="supplier.rfqToSupplierStatus !== 'Priced' && status==='Ordered'"
                   class="part-list-item-price font-12"
                   :class="{'part-list-item-price--priced':supplier.type !== 'OEM-GEN' && supplier.type !== 'OEM-PAR'}">
                <template v-if="supplier.getOffer(part).price > 0">
                  {{ supplier.getOffer(part).price | formatMoney }}
                </template>
              </div>
              <div
                  v-else-if="supplier.isAdditional && part.isAdditional && supplier.additionalRfqToSupplierStatus === 'New'"
                  class="part-list-item-price font-12 color-lightgray d-flex justify-content-center">
                <span v-if="getIsNeedShowPartPrice(supplier, part)" class="">To be Priced</span>
              </div>
              <div
                  v-else-if="supplier.rfqToSupplierStatus !== 'Priced' && (status === 'Priced' || status==='Ordered' || status === 'Cancelled')"
                  class="part-list-item-price font-12 color-lightgray d-flex justify-content-center">
                Not Priced
              </div>
              <div
                  v-else-if="supplier.rfqToSupplierStatus === 'Priced' && !supplier.getOffer(part) && !partIsLinked(part)"
                  class="part-list-item-price font-12">

              </div>
              <div v-else-if="supplier.rfqToSupplierStatus === 'Priced' && !supplier.getOffer(part)"
                   class="part-list-item-price font-12">

              </div>
              <div
                  v-else-if="supplier.rfqToSupplierStatus === 'Priced' && supplier.getOffer(part) && supplier.getOffer(part).stock === 0 && !(supplier.getOffer(part).price>0) && !partIsLinked(part)"
                  class="part-list-item-price font-12">

              </div>
              <div v-else-if="!supplier.getOffer(part)" class="part-list-item-price font-12">
                <span v-if="getIsNeedShowPartPrice(supplier, part)" class="w-100">To be Priced</span>
              </div>
              <div v-else-if="partIsLinked(part) && partIsWithNoCost(part)"
                   class="part-list-item-price has-icon  font-12"
                   :class="{'part-list-item-price--priced':supplier.type !== 'OEM-GEN' && supplier.type !== 'OEM-PAR', 'price-red-blinking': isPriceGrayBlinking(part, supplier),}">
                <template v-if="supplier.type === 'OEM-GEN' || supplier.type === 'OEM-PAR'">
                  <div class="part-list-item-price__icon-group-linked">
                    <i v-if="partIsLinked(part)"
                       class='bx bx-link part-list-price-linked__icon'
                       v-b-tooltip.hover="{customClass: 'ps-tooltip', html: true, title:`Linked to <br>${getPartLinkedName(part)}`, placement:'leftbottom'}"
                    ></i>
                  </div>
                  <div class="linked-text">Linked</div>
                  <div class="part-list-item-price__icon-group-linked"></div>
                </template>
                <template v-else>
                  <template v-if="supplier.getOffer(part).price > 0">
                    {{ supplier.getOffer(part).price | formatMoney }}
                  </template>
                </template>
              </div>
              <div v-else-if="supplier.getOffer(part)"
                   class="part-list-item-price font-12 part-list-item-price--priced"
                   :class="{
                                        'has-icon': supplier.getOffer(part) && (supplier.getOffer(part).flagged || supplier.getOffer(part).comment.length > 0 || part.stock[supplier.id] === 1 || part.stock[supplier.id] === 2 || part.stock[supplier.id] === 6 || part.stock[supplier.id] === 4 || part.stock[supplier.id] === 5),
                                         'green-cell': (Number(supplier.getOffer(part).firstPrice) > 0 && Number(supplier.getOffer(part).firstPrice) < Number(supplier.getOffer(part).price)), //was commented in #390.13 and then uncommented in #451.21
                                        'red-cell':(Number(supplier.getOffer(part).firstPrice) > 0 && Number(supplier.getOffer(part).firstPrice) > Number(supplier.getOffer(part).price)) //was commented in #390.13 and then uncommented in #451.21
                                    }">
                <div class="part-list-item-price__icon-group">
                  <i v-if="isShowImgIcon(supplier, part)" @click="openViewPhotoModal(supplier.getPart(part.id))"
                     v-b-tooltip.hover="{customClass: 'ps-tooltip custom-tooltip-position', html: true, title: 'Photo added of condition. Click to View', placement:'leftbottom'}"
                     class='bx bx-image-alt font-20 text-warning'>
                  </i>
                  <i v-if="partIsLinked(part)"
                     class='bx bx-link part-list-price-linked__icon'
                     v-b-tooltip.hover="{customClass: 'ps-tooltip', html: true, title:`Linked to <br>${getPartLinkedName(part)}`, placement:'leftbottom'}"
                  ></i>
                </div>
                <div class="part-list-item-price__value text-right"
                     :class="{'price-red-blinking': isPriceGrayBlinking(part, supplier),}">
                  <template v-if="supplier.getOffer(part).price > 0">
                    {{ supplier.getOffer(part).price | formatMoney }}
                  </template>
                </div>

                <div v-if="isShowPercents(supplier, part)"
                     :class="{
                    'price-red-blinking': isPriceGrayBlinking(part, supplier)
                 }"
                     style="font-size:10px; text-align: center; position: absolute; bottom: 5px; width: 100%;"
                     class="">
                  {{ getPercentForTooltip(supplier.getOffer(part), part, supplier) }}% of List
                </div>
                <div v-if="isShowMUP(supplier, part)"
                     style="font-size:10px; text-align: center; position: absolute; bottom: 5px; width: 100%;"
                     class="">
                  + M/UP
                </div>

                <!-- icon group -->
                <div class="part-list-item-price__icon-group" :class="{'two-icons-group': supplier.getOffer(part) && supplier.getOffer(part).nonReturnable}">
                  <!-- <i v-if="part.stock[supplier.id] === 1 || part.stock[supplier.id] === 2 || part.stock[supplier.id] === 6"
                     style="color: #3cc7a9; font-size: 20px;"
                     :class="{'orange-icon': part.stock[supplier.id] === 6, 'icon-orange-blinking': getStockComment(part.id)}"
                     v-b-tooltip.hover="{customClass: 'ps-tooltip custom-tooltip-position', html: true, title: getTitleForPart(supplier.getOffer(part), part.stock[supplier.id]), placement:'leftbottom'}"
                     class="bx bx-check-circle">
                  </i>
                  <i class="font-20"
                    v-if="part.stock[supplier.id] === 3 || part.stock[supplier.id] === 4 || part.stock[supplier.id] === 5"
                    :class="{'bx bx-minus-circle gray-icon': part.stock[supplier.id] === 3 || part.stock[supplier.id] === 5,'bx bx-check-circle red-icon icon-red-blinking': part.stock[supplier.id] === 4}"
                    v-b-tooltip.hover="{customClass: 'ps-tooltip custom-tooltip-position', html: true, title: getStockLabel(supplier.getOffer(part), true, supplier.getOffer(part).stockComment), placement:'leftbottom'}">
                  </i>
                  <i v-if="isShowComment(supplier, part, false)"
                    v-b-tooltip.hover="{customClass: 'ps-tooltip custom-tooltip-position', html: true, title: getCommentTooltip(supplier, part), placement:'leftbottom'}"
                    class='bx bx-note font-20 text-warning'>
                  </i>
                  <i class="bx bx-message-detail font-20 text-success"
                    v-if="(supplier.getOffer(part).stock === 2 || supplier.getOffer(part).stock === 6) && supplier.getOffer(part).comment"
                    v-b-tooltip.hover="{customClass: 'ps-tooltip custom-tooltip-position', title: supplier.getOffer(part).comment, placement:'leftbottom'}">
                  </i>
                  <i class="bx bx-purchase-tag-alt orange-icon font-20"
                    v-if="part.stock[supplier.id] === 4"
                    v-b-tooltip.hover="{customClass: 'ps-tooltip custom-tooltip-position', html: true, title: getStockLabel(supplier.getOffer(part)), placement:'leftbottom'}">
                  </i>
                  <i class="bx bx-flag font-20"
                    v-if="supplier.getOffer(part).flagged"
                    :class="{
                    'green-icon': part.stock[supplier.id] === 2,
                    'orange-icon': part.stock[supplier.id] === 3,
                  }"
                    v-b-tooltip.hover="{customClass: 'ps-tooltip custom-tooltip-position', html: true, title: getStockLabel(supplier.getOffer(part)), placement:'leftbottom'}">
                  </i> -->

                  <!------------------->
                  <!-- NEW ICONS GROUP -->
                  <i class="font-20"
                     v-if="(part.stock[supplier.id] >= 0 || part.stock[supplier.id] <= 7) && !((part.stock[supplier.id] == 3 ||  part.stock[supplier.id] == 7) && supplier.getOffer(part).price <= 0)"
                     :class="{
                       'icon-with-text': isShowPercents(supplier, part) || isShowMUP(supplier, part),
                       'transparent-icon bx bx-check-circle': part.stock[supplier.id] === 0,
                      // In Stock
                      'bx bx-check-circle green-icon': part.stock[supplier.id] === 1,
                      // EX Warehouse
                      'bx bx-check-circle orange-icon': part.stock[supplier.id] === 6,
                      'bx bx-check-circle icon-orange-blinking': part.stock[supplier.id] === 6 && getStockComment(part.id),
                      // Low Stock
                      'bx bx-check-circle blue-icon': part.stock[supplier.id] === 2,
                      // No Stock, No Longer Available, Not Applicable
                      'bx bx-minus-circle gray-icon': part.stock[supplier.id] === 3 || part.stock[supplier.id] === 5 || part.stock[supplier.id] === 7,
                      // Back Order
                      'bx bx-check-circle red-icon': part.stock[supplier.id] === 4,
                      'bx bx-check-circle red-icon icon-red-blinking': part.stock[supplier.id] === 4 && getStockComment(part.id),
                    }"
                     @click="clickedOnIcon(supplier.getOffer(part), supplier)"
                     :ref="'icon-' + supplier.id + '-' + supplier.getOffer(part).id"
                     style="height: 46px; width: 22px; padding-top: 10px"
                  >
                  </i>
                  <i
                      v-if="supplier.getOffer(part) && supplier.getOffer(part).nonReturnable"
                      style="height: 46px; width: 22px; padding-top: 10px;"
                      :class="{'icon-with-text': isShowPercents(supplier, part) || isShowMUP(supplier, part),}"
                      v-b-tooltip.hover="{customClass: 'ps-tooltip non-returnable-tooltip', html: true, title: '<b>Marked Non-Returnable Part</b>', placement:'leftbottom'}"
                      class='bx bx-error font-20 orange-icon'></i>

                  <!------------------->

                </div>
              </div>
              <div v-else-if="!supplier.getOffer(part).price"
                   class="part-list-item-price font-12">
                <span v-if="getIsNeedShowPartPrice(supplier, part)" class="">To be Priced</span>
              </div>
              <div v-else>

              </div>
            </template>
            <!-- OPTIONAL PART -->
            <template v-else-if="part.isOptional && supplier.getOffer(part)">
              <div class="part-list-item-price__icon-group">
                <i v-if="isShowImgIcon(supplier, part)" @click="openViewPhotoModal(supplier.getPart(part.id))"
                   v-b-tooltip.hover="{customClass: 'ps-tooltip custom-tooltip-position', html: true, title: 'Photo added of condition. Click to View', placement:'leftbottom'}"
                   class='bx bx-image-alt font-20 text-warning'>
                </i>
              </div>
              <div
                  class="part-list-item-price font-12 part-list-item-price--priced"
                  :class="{'has-icon': (supplier.getOffer(part).flagged || supplier.getOffer(part).comment.length > 0 || part.stock[supplier.id] === 1 || part.stock[supplier.id] === 2 || part.stock[supplier.id] === 6 || part.stock[supplier.id] === 4 || part.stock[supplier.id] === 5)}">
                <div class="part-list-item-price__value text-right"
                     :class="{'price-red-blinking': isPriceGrayBlinking(part, supplier),}">
                  <template v-if="supplier.getOffer(part).price > 0">
                    {{ supplier.getOffer(part).price | formatMoney }}
                  </template>
                </div>

                <div v-if="isShowPercents(supplier, part)"
                     :class="{
                    'price-red-blinking': isPriceGrayBlinking(part, supplier)
                 }"
                     style="font-size:10px; text-align: center; position: absolute; bottom: 5px; width: 100%;"
                     class="">
                  {{ getPercentForTooltip(supplier.getOffer(part), part, supplier) }}% of List
                </div>
                <div v-if="isShowMUP(supplier, part)"
                     style="font-size:10px; text-align: center; position: absolute; bottom: 5px; width: 100%;"
                     class="">
                  + M/UP
                </div>

                <!-- icon group -->
                <div class="part-list-item-price__icon-group" :class="{'two-icons-group': supplier.getOffer(part) && supplier.getOffer(part).nonReturnable}">
                  <!-- <i v-if="part.stock[supplier.id] === 1 || part.stock[supplier.id] === 2 || part.stock[supplier.id] === 6"
                     v-b-tooltip.hover="{customClass: 'ps-tooltip custom-tooltip-position', html: true, title: getTitleForPart(supplier.getOffer(part), part.stock[supplier.id], supplier), placement:'leftbottom'}"
                     style="color: #3cc7a9; font-size: 20px;"
                     :class="{'orange-icon': part.stock[supplier.id] === 6}"
                     class="bx bx-check-circle">
                  </i>
                  <i class="font-20"
                     v-if="part.stock[supplier.id] === 3 || part.stock[supplier.id] === 4 || part.stock[supplier.id] === 5"
                     :class="{'bx bx-minus-circle gray-icon': part.stock[supplier.id] === 3 || part.stock[supplier.id] === 5,'bx bx-check-circle icon-red-blinking': part.stock[supplier.id] === 4}"
                     v-b-tooltip.hover="{customClass: 'ps-tooltip custom-tooltip-position', html: true, title: getStockLabel(supplier.getOffer(part), true, supplier.getOffer(part).stockComment, supplier), placement:'leftbottom'}">
                  </i>
                  <i v-if="isShowComment(supplier, part, false)"
                     v-b-tooltip.hover="{customClass: 'ps-tooltip custom-tooltip-position', html: true, title: getCommentTooltip(supplier, part), placement:'leftbottom'}"
                     class='bx bx-note font-20 text-warning'>
                  </i>
                  <i class="bx bx-message-detail font-20 text-success"
                     v-if="(supplier.getOffer(part).stock === 2 || supplier.getOffer(part).stock === 6) && supplier.getOffer(part).comment"
                     v-b-tooltip.hover="{customClass: 'ps-tooltip custom-tooltip-position', title: supplier.getOffer(part).comment, placement:'leftbottom'}">
                  </i>

                  <i class="bx bx-purchase-tag-alt orange-icon font-20"
                     v-if="part.stock[supplier.id] === 4"
                     v-b-tooltip.hover="{customClass: 'ps-tooltip custom-tooltip-position', html: true, title: getStockLabel(supplier.getOffer(part), false, '', supplier),placement:'leftbottom'}">
                  </i>
                  <i v-if="supplier.getOffer(part).flagged"
                     class="bx bx-flag font-20"
                     :class="{
                       'green-icon': part.stock[supplier.id] === 2,
                       'orange-icon': part.stock[supplier.id] === 3,
                      }"
                     v-b-tooltip.hover="{customClass: 'ps-tooltip custom-tooltip-position', html: true, title: getStockLabel(supplier.getOffer(part), false, '', supplier), placement:'leftbottom'}">
                  </i>
                  <i class="bx bx-minus-circle font-20 gray-icon"
                     v-if="part.stock[supplier.id] === 3 || part.stock[supplier.id] === 4 || part.stock[supplier.id] === 5"

                     v-b-tooltip.hover="{customClass: 'ps-tooltip custom-tooltip-position', html: true, title: getStockLabel(supplier.getOffer(part), true, supplier.getOffer(part).stockComment, supplier), placement:'leftbottom'}">
                  </i> -->

                  <!------------------->
                  <!-- NEW ICONS GROUP -->
                  <i class="font-20"
                     v-if="(part.stock[supplier.id] >= 0 || part.stock[supplier.id] <= 7) && !((part.stock[supplier.id] == 3 ||  part.stock[supplier.id] == 7) && supplier.getOffer(part).price <= 0)"
                     :class="{
                       'icon-with-text': isShowPercents(supplier, part) || isShowMUP(supplier, part),
                        'transparent-icon bx bx-check-circle': part.stock[supplier.id] === 0,
                      // In Stock
                      'bx bx-check-circle green-icon': part.stock[supplier.id] === 1,
                      // EX Warehouse
                      'bx bx-check-circle orange-icon': part.stock[supplier.id] === 6,
                      'bx bx-check-circle icon-orange-blinking': part.stock[supplier.id] === 6 && getStockComment(part.id),
                      // Low Stock
                      'bx bx-check-circle blue-icon': part.stock[supplier.id] === 2,
                      // No Stock, No Longer Available, Not Applicable
                      'bx bx-minus-circle gray-icon': part.stock[supplier.id] === 3 || part.stock[supplier.id] === 5 || part.stock[supplier.id] === 7,
                      // Back Order
                      'bx bx-check-circle red-icon': part.stock[supplier.id] === 4,
                      'bx bx-check-circle red-icon icon-red-blinking': part.stock[supplier.id] === 4 && getStockComment(part.id),
                    }"
                     @click="clickedOnIcon(supplier.getOffer(part), supplier)"
                     :ref="'icon-' + supplier.id + '-' + supplier.getOffer(part).id"
                     style="height: 46px; width: 22px; padding-top: 10px"
                  >
                  </i>
                  <i
                      v-if="supplier.getOffer(part) && supplier.getOffer(part).nonReturnable"
                      style="height: 46px; width: 22px; padding-top: 10px;"
                      :class="{'icon-with-text': isShowPercents(supplier, part) || isShowMUP(supplier, part),}"
                      v-b-tooltip.hover="{customClass: 'ps-tooltip non-returnable-tooltip', html: true, title: '<b>Marked Non-Returnable Part</b>', placement:'leftbottom'}"
                      class='bx bx-error font-20 orange-icon'></i>
                  <!------------------->

                </div>
              </div>
            </template>
            <template v-else-if="supplier.getOffer(part)">
              <div :class="{
                'd-flex align-items-center': isShowImgIcon(supplier, part),
              }" class="w-100">
                <i v-if="isShowImgIcon(supplier, part)" @click="openViewPhotoModal(supplier.getPart(part.id))"
                   v-b-tooltip.hover="{customClass: 'ps-tooltip custom-tooltip-position', html: true, title: 'Photo added of condition. Click to View', placement:'leftbottom'}"
                   class='bx bx-image-alt font-20 text-warning'>
                </i>
                <div v-if="partIsWithNoCost(part) || !supplier.parts[0] || !supplier.parts[0][part.id]"
                     class="part-list-item-price has-icon font-12">
                  <!-- icon group -->
                  <div class="part-list-item-price__icon-group-linked">
                    <i v-if="partIsLinked(part)"
                       class='bx bx-link part-list-price-linked__icon'
                       v-b-tooltip.hover="{customClass: 'ps-tooltip', html: true, title:`Linked to <br>${getPartLinkedName(part)}`, placement:'leftbottom'}"
                    ></i>
                  </div>
                  <div class="linked-text">{{ partIsWithNoCost(part) ? 'Linked' : '' }}</div>
                  <div class="part-list-item-price__icon-group-linked"></div>
                </div>

                <div v-else-if="!supplier.parts[0][part.id].price"
                     class="part-list-item-price font-12">
                  <template v-if="supplier.parts[0][part.id].linkId">$0</template>
                  <template v-else-if="part.type === 'manual' && isRFQSubmitted">
                    <span v-if="supplier.type === 'OEM-GEN' || supplier.type === 'OEM-PAR'" class="w-100">
                     <span v-if="getIsNeedShowPartPrice(supplier, part)" class="">To be Priced</span>
                    </span>
                  </template>
                  <template v-else-if="supplier.rfqToSupplierStatus === 'New'">
                    <span v-if="getIsNeedShowPartPrice(supplier, part)" class="">To be Priced</span>
                  </template>
                </div>
                <div v-else
                     class="part-list-item-price font-12 part-list-item-price--priced d-flex"
                     :class="{
                                    'price-red-blinking': isPriceGrayBlinking(part, supplier),
                                    'has-icon': (supplier.parts[0][part.id].flagged || supplier.parts[0][part.id].comment.length > 0 || part.stock[supplier.id] === 1 || part.stock[supplier.id] === 2 || part.stock[supplier.id] === 6|| part.stock[supplier.id] === 4 || part.stock[supplier.id] === 5) && !supplier.parts[0][part.id].isDelivery,
                                    'green-cell': (Number(supplier.getOffer(part).firstPrice) > 0 && Number(supplier.getOffer(part).firstPrice) < Number(supplier.getOffer(part).price)),
                                    'red-cell':(Number(supplier.getOffer(part).firstPrice) > 0 && Number(supplier.getOffer(part).firstPrice) > Number(supplier.getOffer(part).price))
                                  }">
                  <div class="part-list-item-price__icon-group-linked">
                    <i v-if="partIsLinked(part)"
                       class='bx bx-link part-list-price-linked__icon'
                       v-b-tooltip.hover="{customClass: 'ps-tooltip', html: true, title:`Linked to <br>${getPartLinkedName(part)}`, placement:'leftbottom'}"
                    ></i>
                  </div>
                  <div class="part-list-item-price__value text-right">
                    <template v-if="supplier.parts[0][part.id].price > 0">
                      {{ supplier.parts[0][part.id].price | formatMoney }}
                    </template>
                  </div>

                  <div v-if="isShowPercents(supplier, part)"
                       :class="{
                    'price-red-blinking': isPriceGrayBlinking(part, supplier)
                 }"
                       style="font-size:10px; text-align: center; position: absolute; bottom: 5px; width: 100%;"
                       class="">
                    {{ getPercentForTooltip(supplier.getOffer(part), part, supplier) }}% of List
                  </div>
                  <div v-if="isShowMUP(supplier, part)"
                       style="font-size:10px; text-align: center; position: absolute; bottom: 5px; width: 100%;"
                       class="">
                    + M/UP
                  </div>

                  <!-- icon group -->

                  <div v-if="!supplier.parts[0][part.id].isDelivery" class="part-list-item-price__icon-group" :class="{'two-icons-group': supplier.getOffer(part) && supplier.getOffer(part).nonReturnable}">
                    <!-- <i v-if="part.stock[supplier.id] === 1 || part.stock[supplier.id] === 2 || part.stock[supplier.id] === 6"
                       style="color: #3cc7a9; font-size: 20px;"
                       :class="{'orange-icon': part.stock[supplier.id] === 6}"
                       v-b-tooltip.hover="{customClass: 'ps-tooltip custom-tooltip-position', html: true, title: getTitleForPart(supplier.getOffer(part), part.stock[supplier.id], supplier), placement:'leftbottom'}"
                       class="bx bx-check-circle">
                    </i>
                    <i class="font-20"
                       v-if="part.stock[supplier.id] === 3 || part.stock[supplier.id] === 4 || part.stock[supplier.id] === 5"
                       :class="{'bx bx-minus-circle gray-icon': part.stock[supplier.id] === 3 || part.stock[supplier.id] === 5,'bx bx-check-circle icon-red-blinking': part.stock[supplier.id] === 4}"
                       v-b-tooltip.hover="{customClass: 'ps-tooltip custom-tooltip-position', html: true, title: getStockLabel(supplier.parts[0][part.id], true, supplier.parts[0][part.id].stockComment, supplier), placement:'leftbottom'}">
                    </i>
                    <i v-if="isShowComment(supplier, part, true)"
                       v-b-tooltip.hover="{customClass: 'ps-tooltip custom-tooltip-position', html: true, title: getCommentTooltip(supplier, part, true), placement:'leftbottom'}"
                       class='bx bx-note font-20 text-warning'>
                    </i>
                    <i class="bx bx-message-detail font-20 text-success"
                       v-if="(supplier.parts[0][part.id].stock === 2 || supplier.parts[0][part.id].stock === 6) && supplier.parts[0][part.id].comment"
                       v-b-tooltip.hover="{customClass: 'ps-tooltip custom-tooltip-position', title: supplier.parts[0][part.id].comment, placement:'leftbottom'}">
                    </i>

                    <i class="bx bx-purchase-tag-alt orange-icon font-20"
                       v-if="part.stock[supplier.id] === 4"
                       v-b-tooltip.hover="{customClass: 'ps-tooltip custom-tooltip-position', html: true, title: getStockLabel(supplier.getOffer(part), false, '', supplier), placement:'leftbottom'}">
                    </i>
                    <i v-if="supplier.parts[0][part.id].flagged"
                       class="bx bx-flag font-20"
                       :class="{
                    'green-icon': part.stock[supplier.id] === 2,
                    'orange-icon': part.stock[supplier.id] === 3,
                   }"
                       v-b-tooltip.hover="{customClass: 'ps-tooltip custom-tooltip-position', html: true, title: getStockLabel(supplier.parts[0][part.id], false, '', supplier), placement:'leftbottom'}">
                    </i>
                    <i class="bx bx-minus-circle font-20 gray-icon"
                       v-if="part.stock[supplier.id] === 3 || part.stock[supplier.id] === 4 || part.stock[supplier.id] === 5"

                   v-b-tooltip.hover="{customClass: 'ps-tooltip custom-tooltip-position', html: true, title: getStockLabel(supplier.parts[0][part.id], true, supplier.parts[0][part.id].stockComment, supplier), placement:'leftbottom'}">
                    </i> -->

                    <!------------------->
                    <!-- NEW ICONS GROUP -->
                    <i class="font-20"
                       v-if="(part.stock[supplier.id] >= 0 || part.stock[supplier.id] <= 7) && !((part.stock[supplier.id] == 3 ||  part.stock[supplier.id] == 7) && supplier.parts[0][part.id].price <= 0)"
                       :class="{
                         'icon-with-text': isShowPercents(supplier, part) || isShowMUP(supplier, part),
                        'transparent-icon bx bx-check-circle': part.stock[supplier.id] === 0,
                      // In Stock
                      'bx bx-check-circle green-icon': part.stock[supplier.id] === 1,
                      // EX Warehouse
                      'bx bx-check-circle orange-icon': part.stock[supplier.id] === 6,
                      'bx bx-check-circle icon-orange-blinking': part.stock[supplier.id] === 6 && getStockComment(part.id),
                      // Low Stock
                      'bx bx-check-circle blue-icon': part.stock[supplier.id] === 2,
                      // No Stock, No Longer Available, Not Applicable
                      'bx bx-minus-circle gray-icon': part.stock[supplier.id] === 3 || part.stock[supplier.id] === 5 || part.stock[supplier.id] === 7,
                      // Back Order
                      'bx bx-check-circle red-icon': part.stock[supplier.id] === 4,
                      'bx bx-check-circle red-icon icon-red-blinking': part.stock[supplier.id] === 4 && getStockComment(part.id),
                    }"
                       @click="clickedOnIcon(supplier.getOffer(part), supplier)"
                       :ref="'icon-' + supplier.id + '-' + supplier.getOffer(part).id"
                       style="height: 46px; width: 22px; padding-top: 10px"
                    >
                    </i>
                    <i
                        v-if="supplier.getOffer(part) && supplier.getOffer(part).nonReturnable"
                        style="height: 46px; width: 22px; padding-top: 10px;"
                        :class="{'icon-with-text': isShowPercents(supplier, part) || isShowMUP(supplier, part),}"
                        v-b-tooltip.hover="{customClass: 'ps-tooltip non-returnable-tooltip', html: true, title: '<b>Marked Non-Returnable Part</b>', placement:'leftbottom'}"
                        class='bx bx-error font-20 orange-icon'></i>
                    <!------------------->

                  </div>
                  <div v-else-if="isShowConditionIcon(supplier, part, true) || supplier.parts[0][part.id].stockComment">
                    <i
                        v-b-tooltip.hover="{customClass: 'ps-tooltip custom-tooltip-position', html: true, title: getCommentTooltip(supplier, part, true), placement:'leftbottom'}"
                        class='bx bx-note font-20 text-warning'>
                    </i>
                  </div>
                  <div v-else class="part-list-item-price__icon-group-wrapper"></div>
                </div>
              </div>
            </template>
            <template v-else>
              <div class="part-list-item-price font-12">
                <span
                    v-if="(supplier.type === 'OEM-GEN' || supplier.type === 'OEM-PAR') && part.type === 'manual' && isRFQSubmitted"
                    class="w-100">
                  <span v-if="getIsNeedShowPartPrice(supplier, part)" class="">To be Priced</span>
                </span>
              </div>
            </template>
            <template
                v-if="(isRFQPriced || isRFQSubmitted) && rfqId && $refs['icon-' + supplier.id + '-' + supplier.getOffer(part).id]">
              <template v-if="isShowSimpleTooltip(supplier.getOffer(part), supplier)">
                <b-tooltip
                    v-if="part.stock[supplier.id]"
                    :target="() => $refs['icon-' + supplier.id + '-' + supplier.getOffer(part).id]?.at(0)"
                    :customClass="getTooltipClass(supplier.getOffer(part), supplier, index)"
                    :placement="getTooltipPlacement(supplier.getOffer(part), supplier)"
                    triggers="hover">
                  <div v-html="getPartTooltip(supplier.getOffer(part), supplier, part.stock[supplier.id])"></div>
                </b-tooltip>
              </template>
              <template v-else-if="isRFQPriced">
                <b-tooltip
                    :target="() => $refs['icon-' + supplier.id + '-' + supplier.getOffer(part).id]?.at(0)"
                    :customClass="getTooltipClass(supplier.getOffer(part), supplier, index)"
                    :placement="getTooltipPlacement(supplier.getOffer(part), supplier)"
                    @show="onShowTooltip(supplier.getOffer(part), supplier)"
                    triggers="hover">

                  <template
                      v-if="!_isEmpty(purchasePriceSelector?.display) && !_isEmpty(supplier.getOffer(part).purchasePriceFigures)">
                    <div class="tooltip-each-price-information-block"
                         :class="{'tooltip-each-price-information-block-last': supplierPrice.orderedSuppliers && index + 1 == supplierPrice.orderedSuppliers.length}">
                      <div class="tooltip-each-price-information-block-inner">

                        <div class="d-flex">
                          <p class="col-4 p-0 text-left font-weight-600">Part Number </p>
                          <p class="col-7 text-left p-0">{{ supplier.getOffer(part).partNumber }}</p>
                          <p v-if="supplier.getOffer(part).purchasePriceFigures.sellPrice !== null && part.stock[supplier.id] !== 3"
                             class="col-1 text-left p-0">
                            <i v-if="supplier.getOffer(part).purchasePriceFigures.editMode"
                               @click="stopEditPartsTooltip(supplier.getOffer(part), supplier)"
                               class='bx bxs-edit clickable'
                               style="font-size:18px; transform: translateX(5px)"></i>
                            <i v-else @click="editPartsTooltip(supplier.getOffer(part), supplier, true)"
                               class='bx bx-edit clickable' style="font-size:18px; transform: translateX(5px)"></i>
                          </p>
                        </div>
                        <template v-if="!supplier.isSupplierTypeOEMGEN && supplier.getOffer(part) && supplier.getOffer(part).oePartNumber">
                          <div class="d-flex">
                            <p class="col-4 p-0 text-left font-weight-600">OE Ref Number </p>
                            <p class="col-8 text-left p-0">{{ supplier.getOffer(part).oePartNumber }}</p>
                          </div>
                        </template>
                        <template v-if="purchasePriceSelector.display.stockStatus">
                          <div class="d-flex">
                            <p class="col-4 text-left p-0 font-weight-600">Stock Status </p>
                            <div class="d-flex align-items-center">
                              <p class="text-left p-0" style="margin-right: 8px;">
                                {{ getStockLabel(supplier.getOffer(part).stock) }}
                              </p>
                              <i class="font-20"
                                 style="    transform: translateY(-7px);"
                                 v-if="part.stock[supplier.id] > 0 || part.stock[supplier.id] <= 7"
                                 :class="{
                                  // In Stock
                                  'bx bx-check-circle green-icon': part.stock[supplier.id] === 1,
                                  // EX Warehouse
                                  'bx bx-check-circle orange-icon': part.stock[supplier.id] === 6,
                                  'bx bx-check-circle icon-orange-blinking': part.stock[supplier.id] === 6 && getStockComment(part.id),
                                  // Low Stock
                                  'bx bx-check-circle blue-icon': part.stock[supplier.id] === 2,
                                  // No Stock, No Longer Available, Not Applicable
                                  'bx bx-minus-circle gray-icon': part.stock[supplier.id] === 3 || part.stock[supplier.id] === 5 || part.stock[supplier.id] === 7,
                                  // Back Order
                                  'bx bx-check-circle red-icon': part.stock[supplier.id] === 4,
                                  'bx bx-check-circle red-icon icon-red-blinking': part.stock[supplier.id] === 4 && getStockComment(part.id),
                                }"
                              >
                              </i>
                            </div>
                          </div>
                          <!--                        Added by #269.29-->
                          <template v-if="getStockCommentForTooltip(supplier.getOffer(part), supplier)">
                            <div class="d-flex" style="margin-bottom: 10px;">
                              <p class="col-4 text-left p-0 font-weight-600"></p>
                              <div class="d-flex align-items-center">
                                <p class="text-left p-0" style="margin-right: 8px;">
                                  {{ getStockCommentForTooltip(supplier.getOffer(part), supplier) }}</p>
                              </div>
                            </div>
                          </template>

                        </template>

                        <template v-if="part.stock[supplier.id] !== 3">
                          <template v-if="isOemGenPartHasLinkedParts(part, supplier)">
                            <template v-if="purchasePriceSelector.display.listPrice">
                              <div class="d-flex align-items-center mb-2">
                                <p class="col-4 text-left p-0 mb-0 font-weight-600">Combined <br/> List Price</p>
                                <div
                                    class="col-8 p-0 form-control tooltip-each-price-information-input font-weight-normal">
                                  {{
                                    formatValue(getCombinedListPrice(OEMSupplier, OEMSupplier.getOffer(part), supplier.getOffer(part).purchasePriceFigures.listPrice))
                                  }}
                                </div>
                              </div>
                            </template>
                          </template>

                          <template v-else-if="purchasePriceSelector.display.listPrice">
                            <div class="d-flex align-items-center mb-2">
                              <p class="col-4 text-left p-0 mb-0 font-weight-600">List Price</p>
                              <div
                                  class="col-8 p-0 form-control tooltip-each-price-information-input font-weight-normal">
                                {{ formatValue(supplier.getOffer(part).purchasePriceFigures.listPrice) }}
                              </div>
                            </div>
                          </template>

                          <template v-if="purchasePriceSelector.display.sellPrice && isShowChargePrice(supplier)">
                            <div class="d-flex align-items-center mb-2">
                              <p class="col-4 text-left p-0 mb-0 font-weight-600">Sell Price</p>
                              <div
                                  class="col-4 p-0 form-control tooltip-each-price-information-input font-weight-normal">
                                {{ getChargePriceForTooltip(supplier.getOffer(part), part, supplier) }}
                              </div>

                              <template v-if="supplier.getOffer(part).purchasePriceFigures.editMode">
                                <p class="col-4 text-left p-0 mb-0 pl-3 font-weight-600">

                                  <input
                                      v-model="newPercentForTooltip"
                                      :ref="'percent-input-' + supplier.getOffer(part).id"
                                      @change="updateTooltipPercent(supplier.getOffer(part), supplier, part)"
                                      @input="supplier.getOffer(part).purchasePriceFigures.sellPricePercent = newPercentForTooltip"
                                      class="form-control tooltip-each-price-information-input font-weight-normal "
                                      style="padding-left: 5px; padding-right: 5px; width:32px; max-width: 32px; min-width: 32px; display: inline-block;"
                                  />
                                  <span class="text-left p-0 mb-0  font-weight-600"
                                        style="margin-left: 3px">% of List</span>
                                </p>
                              </template>
                              <template v-else-if="getPercentForTooltip(supplier.getOffer(part), part, supplier)">
                                <p class="col-4 text-left p-0 mb-0 pl-3 font-weight-600">
                                  {{ getPercentForTooltip(supplier.getOffer(part), part, supplier) }}% of List</p>
                              </template>

                              <div
                                  v-if="supplier.getOffer(part).purchasePriceFigures.sellPrice !== null
                          && Number(getCostPricePercentageForTooltip(supplier.getOffer(part), part, supplier)) !== Number(purchasePriceSelector.listPricePercents.markUp)"
                                  class="col-1 p-0 tooltip-checkbox"
                              >
                                <label class="form-check-inline checkbox tooltip-purchase-checkbox">
                                  <input class="form-check-input"
                                         @change="onChangedOnCheckbox(1, supplier.getOffer(part), supplier, part)"
                                         v-model="supplier.getOffer(part).purchasePriceFigures.sellPriceCheck"
                                         type="checkbox"
                                  >
                                  <span @click="onChangedSellPriceCheckBox(supplier, part)" class="icon clickable"
                                        style="transform: translate(1px, 3px);">
                                <i class='bx bx-check'></i></span>
                                </label>
                              </div>

                            </div>
                          </template>

                          <template v-if="purchasePriceSelector?.display?.costPrice">
                            <div class="d-flex mt-3 mb-2">
                              <p class="col-4 text-left p-0"></p>
                              <p class="col-4 text-left p-0 pb-2 tooltip-each-price-information-line font-weight-600">
                                Purchase Price</p>
                              <p v-if="purchasePriceSelector.display.markUpPercentage"
                                 class="col-4 text-left p-0 pb-2 pl-3 tooltip-each-price-information-line font-weight-600">
                                Markup %
                              </p>
                            </div>
                            <div class="d-flex align-items-center mb-2">
                              <p class="col-4 text-left p-0 mb-0 font-weight-900">Cost Price</p>
                              <div class="col-4 p-0 form-control tooltip-each-price-information-input font-weight-900">
                                {{ formatValue(supplier.getOffer(part).purchasePriceFigures?.costPrice) }}
                              </div>
                              <p :class="{
                                    'red-cell': Number(getCostPricePercentageForTooltip(supplier.getOffer(part), part, supplier)) < Number(purchasePriceSelector.listPricePercents.markUp),
                                    'green-cell': Number(getCostPricePercentageForTooltip(supplier.getOffer(part), part, supplier)) >= Number(purchasePriceSelector.listPricePercents.markUp)
                                  }"
                                 class="col-4 text-left p-0 mb-0 pl-3 font-weight-900">
                                <!--                            <span-->
                                <!--                                v-if="Number(getCostPricePercentageForTooltip(supplier.getOffer(part))) < Number(purchasePriceSelector.listPricePercents.markUp)">-</span>-->
                                <!--                            <span v-else>+</span>-->
                                <!--                            Removal By #296.30-->
                                <span>{{
                                    getCostPricePercentageForTooltip(supplier.getOffer(part), part, supplier)
                                  }}%</span>
                              </p>
                            </div>
                          </template>

                          <template v-if="purchasePriceSelector.display.profitValue">
                            <div class="d-flex align-items-center mb-2">
                              <p class="col-4 text-left p-0 mb-0 font-weight-600">Profit Value</p>
                              <div
                                  class="col-8 p-0 form-control tooltip-each-price-information-input font-weight-normal">
                                <template v-if="supplier.getOffer(part).purchasePriceFigures.sellPricePercent">
                                  {{
                                    formatValue((Number(supplier.getOffer(part).purchasePriceFigures.sellPricePercent) * Number(getListPrice(supplier.getOffer(part), part, supplier)) / 100) - Number(supplier.getOffer(part).purchasePriceFigures?.costPrice))
                                  }}
                                </template>
                                <template v-else>
                                  {{ formatValue(supplier.getOffer(part).purchasePriceFigures.profitValue) }}
                                </template>
                              </div>
                            </div>
                          </template>

                          <template
                              v-if="purchasePriceSelector.display.discountPrice && supplier.getOffer(part).purchasePriceFigures.discountPrice > 0">
                            <div class="d-flex" style="margin-top: 13px;">
                              <p class="col-4"></p>
                              <p class="col-8 tooltip-each-price-information-line"></p>
                            </div>
                            <div class="d-flex align-items-center mb-2">
                              <p class="col-4 text-left p-0 mb-0 font-weight-600">Disc Price</p>
                              <div
                                  class="col-8 p-0 form-control tooltip-each-price-information-input font-weight-normal">
                                {{ formatValue(supplier.getOffer(part).purchasePriceFigures.discountPrice) }}
                              </div>
                              <template v-if="purchasePriceSelector.display.profitValue">
                                <p class="col-4 text-left p-0 mb-0 pl-3 font-weight-900">
                            <span>
                              {{
                                formatPercent((100 * supplier.getOffer(part).purchasePriceFigures.discountProfitValue) / supplier.getOffer(part).purchasePriceFigures.discountPrice, 2)
                              }}%</span>
                                </p>
                              </template>
                            </div>
                            <div class="d-flex align-items-center mb-2">
                              <p class="col-4 text-left p-0 mb-0 font-weight-600">Disc Profit Value</p>
                              <div
                                  class="col-8 p-0 form-control tooltip-each-price-information-input font-weight-normal">
                                {{ formatValue(supplier.getOffer(part).purchasePriceFigures.discountProfitValue) }}
                              </div>
                            </div>
                          </template>

                          <template
                              v-if="supplier.getOffer(part).purchasePriceFigures.sellPrice !== null
                          && Number(getCostPricePercentageForTooltip(supplier.getOffer(part), part, supplier)) < Number(purchasePriceSelector.listPricePercents.markUp)">
                            <div class="d-flex" style="margin-top: 13px;">
                              <p class="col-4"></p>
                              <p class="col-8 tooltip-each-price-information-line"></p>
                            </div>
                            <div class="d-flex align-items-center mb-2">
                              <p class="col-4 text-left p-0 mb-0 font-weight-600">M/UP % Price</p>

                              <template v-if="supplier.getOffer(part).purchasePriceFigures.editMode">
                                <div class="col-4 text-left p-0 mb-0 font-weight-600"
                                     style="border: 1px solid transparent !important;">
                                  <number-formatter
                                      v-model="supplier.getOffer(part).purchasePriceFigures.markUpAndPrice"
                                      class="form-control tooltip-each-price-information-input font-weight-normal text-center"
                                      @change="updateTooltipPercent(supplier.getOffer(part), supplier, part)"
                                      style="border: 1px solid #1c1f3940 !important;"
                                      format="$0,0.00"
                                  ></number-formatter>
                                </div>
                              </template>
                              <template v-else>
                                <!--                            <div v-if="formatValue(supplier.getOffer(part).purchasePriceFigures.discountProfitValue)>0"-->
                                <!--                                 class="col-4 p-0 form-control tooltip-each-price-information-input font-weight-normal">-->
                                <!--                              {{ formatValue(supplier.getOffer(part).purchasePriceFigures.discountProfitValue) }}-->
                                <!--                            </div>-->
                                <!--                            <div v-else class="col-4 p-0 form-control tooltip-each-price-information-input font-weight-normal">-->
                                <!--                              {{ formatValue(supplier.getOffer(part).price) }}-->
                                <!--                            </div>-->
                                <div
                                    class="col-4 p-0 form-control tooltip-each-price-information-input font-weight-normal"
                                    style="border: 1px solid #1c1f3940 !important;">
                                  <template v-if="supplier.getOffer(part).purchasePriceFigures.markUpAndPrice">
                                    {{ formatValue(supplier.getOffer(part).purchasePriceFigures.markUpAndPrice) }}
                                  </template>
                                  <template v-else>
                                    {{
                                      formatValue(supplier.getOffer(part).price + (supplier.getOffer(part).price * (Number(purchasePriceSelector.listPricePercents.markUp) / 100)))
                                    }}
                                  </template>
                                </div>
                              </template>
                              <p
                                  class="col-4 text-left p-0 mb-0 font-weight-900"
                                  :class="{
                                'pl-3': !supplier.getOffer(part).purchasePriceFigures.editMode,
                                'width-3-markup-and-price': supplier.getOffer(part).purchasePriceFigures.editMode,
                                'fixed-position-markup-and-price': supplier.getOffer(part).purchasePriceFigures.editMode,
                              }"
                              >
                                <span>{{ getMarkUpPercentForTooltip(supplier.getOffer(part)) }}%</span>
                              </p>

                              <div class="col-1 p-0 tooltip-checkbox">
                                <label class="form-check-inline checkbox tooltip-purchase-checkbox">
                                  <input class="form-check-input"
                                         @change="onChangedOnCheckbox(2, supplier.getOffer(part), supplier, part)"
                                         v-model="supplier.getOffer(part).purchasePriceFigures.markUpAndPriceCheck"
                                         type="checkbox"
                                  >
                                  <span @click="onChangedMarkUpCheckBox(supplier, part)" class="icon clickable"
                                        style="transform: translate(1px, 3px);">
                                <i class='bx bx-check'></i></span>
                                </label>
                              </div>
                            </div>

                            <template v-if="purchasePriceSelector.display.profitValue">
                              <div class="d-flex align-items-center mb-2">
                                <p class="col-4 text-left p-0 mb-0 font-weight-600">Profit Value</p>
                                <div
                                    class="col-8 p-0 form-control tooltip-each-price-information-input font-weight-normal">
                                  {{
                                    formatValue(getMUpProfitValue(supplier, part))
                                  }}
                                </div>
                              </div>
                            </template>
                          </template>

                          <template v-if="isPartHasLinkedParts(supplier.getOffer(part).id, supplier)">
                            <div class="d-flex" style="margin-top: 13px;">
                              <p class="col-4"></p>
                              <p class="col-8 tooltip-each-price-information-line"></p>
                            </div>
                            <template v-if="purchasePriceSelector.display.listPrice">
                              <div class="d-flex align-items-center mb-2">
                                <p class="col-4 text-left p-0 mb-0 font-weight-600">Combined <br/> List Price</p>
                                <div
                                    class="col-8 p-0 form-control tooltip-each-price-information-input font-weight-normal">
                                  {{ formatValue(getCombinedListPrice(supplier, supplier.getOffer(part))) }}
                                </div>
                              </div>
                            </template>

                            <template v-if="purchasePriceSelector.display?.costPrice">
                              <div class="d-flex mt-3 mb-2">
                                <p class="col-4 text-left p-0"></p>
                                <p class="col-4 text-left p-0 pb-2 tooltip-each-price-information-line font-weight-600">
                                  Purchase Price</p>
                                <p v-if="purchasePriceSelector.display.markUpPercentage"
                                   class="col-4 text-left p-0 pb-2 pl-3 tooltip-each-price-information-line font-weight-600">
                                  Markup %
                                </p>
                              </div>
                              <div class="d-flex align-items-center mb-2">
                                <p class="col-4 text-left p-0 mb-0 font-weight-900">Combined <br/> Cost Price</p>
                                <div
                                    class="col-4 p-0 form-control tooltip-each-price-information-input font-weight-900">
                                  {{ formatValue(getCombinedCostPrice(supplier, supplier.getOffer(part))) }}
                                </div>
                                <p :class="{
                                    'red-cell': Number(getCombinedCostPriceMarkUp(supplier, supplier.getOffer(part))) < Number(purchasePriceSelector.listPricePercents.markUp),
                                    'green-cell': Number(getCombinedCostPriceMarkUp(supplier, supplier.getOffer(part))) >= Number(purchasePriceSelector.listPricePercents.markUp)
                                  }"
                                   class="col-4 text-left p-0 mb-0 pl-3 font-weight-900">
                                  <!--                            <span-->
                                  <!--                                v-if="Number(getCostPricePercentageForTooltip(supplier.getOffer(part))) < Number(purchasePriceSelector.listPricePercents.markUp)">-</span>-->
                                  <!--                            <span v-else>+</span>-->
                                  <!--                            Removal By #296.30-->
                                  <span>{{ getCombinedCostPriceMarkUp(supplier, supplier.getOffer(part)) }}%</span>
                                </p>
                              </div>
                            </template>

                            <template v-if="purchasePriceSelector.display.profitValue">
                              <div class="d-flex align-items-center mb-2">
                                <p class="col-4 text-left p-0 mb-0 font-weight-600">Profit Value</p>
                                <div
                                    class="col-8 p-0 form-control tooltip-each-price-information-input font-weight-normal">
                                  {{ formatValue(getCombinedProfitValue(supplier, supplier.getOffer(part))) }}
                                </div>
                              </div>
                            </template>
                          </template>
                        </template>

                      </div>
                    </div>
                  </template>
                  <template v-else>
                    <div class="tooltip-each-price-information-block"
                         :class="{'tooltip-each-price-information-block-last': supplierPrice.orderedSuppliers && index + 1 == supplierPrice.orderedSuppliers.length}">
                      <div class="tooltip-each-price-information-block-inner">
                        <div class="d-flex">
                          <p class="col-4 p-0 text-left font-weight-600">Part Number </p>
                          <p class="col-7 text-left p-0">{{ getPartNumber(part.id) }}</p>
                        </div>
                        <div class="d-flex">
                          <p class="col-4 text-left p-0 font-weight-600">Stock Status </p>
                          <div class="d-flex align-items-center">
                            <p class="text-left p-0" style="margin-right: 8px;">
                              {{ getStockLabel(supplier.getOffer(part).stock) }}
                            </p>
                            <i class="font-20"
                               style="    transform: translateY(-7px);"
                               v-if="part.stock[supplier.id] > 0 || part.stock[supplier.id] <= 7"
                               :class="{
                                  // In Stock
                                  'bx bx-check-circle green-icon': part.stock[supplier.id] === 1,
                                  // EX Warehouse
                                  'bx bx-check-circle orange-icon': part.stock[supplier.id] === 6,
                                  'bx bx-check-circle icon-orange-blinking': part.stock[supplier.id] === 6 && getStockComment(part.id),
                                  // Low Stock
                                  'bx bx-check-circle blue-icon': part.stock[supplier.id] === 2,
                                  // No Stock, No Longer Available, Not Applicable
                                  'bx bx-minus-circle gray-icon': part.stock[supplier.id] === 3 || part.stock[supplier.id] === 5 || part.stock[supplier.id] === 7,
                                  // Back Order
                                  'bx bx-check-circle red-icon': part.stock[supplier.id] === 4,
                                  'bx bx-check-circle red-icon icon-red-blinking': part.stock[supplier.id] === 4 && getStockComment(part.id),
                                }"
                            >
                            </i>
                          </div>
                        </div>
                        <div v-if="getStockCommentForTooltip(supplier.getOffer(part), supplier)" class="d-flex">
                          <p>{{ getStockCommentForTooltip(supplier.getOffer(part), supplier) }}</p>
                        </div>

                        <div class="d-flex align-items-center mb-2">
                          <p class="col-4 text-left p-0 mb-0 font-weight-600">List Price</p>
                          <div class="col-8 p-0 form-control tooltip-each-price-information-input font-weight-normal">
                            {{ formatValue(getRrp(supplier.getOffer(part).originalPartId)) }}
                          </div>
                        </div>
                        <div class="d-flex align-items-center mb-2">
                          <p class="col-4 text-left p-0 mb-0 font-weight-900">Cost Price</p>
                          <div class="col-4 p-0 form-control tooltip-each-price-information-input font-weight-900">
                            {{ formatValue(supplier.getOffer(part).price) }}
                          </div>
                        </div>

                      </div>
                    </div>
                  </template>
                </b-tooltip>
              </template>
            </template>
            <div
                v-if="isDarkGreyTriangle(supplier.getOffer(part), supplier) || isGreenTriangle(supplier.getOffer(part), supplier)"
                :class="{
                  'dark-grey-triangle-icon': isDarkGreyTriangle(supplier.getOffer(part), supplier),
                  'dark-green-triangle-icon': isGreenTriangle(supplier.getOffer(part), supplier),
                }"
                v-b-tooltip.hover="{customClass: 'ps-tooltip', html: true, title: getTooltipTextForTriagle(supplier.getOffer(part), supplier), placement:'leftbottom'}"></div>
          </b-td>
          <b-td v-if="status === 'Received'" class="part-list-action text-center">
            <div class="d-flex">
              <template v-if="!part.isAdditional">
                <i v-if="!part.isEdit" v-on:click="editPart(part)" class='bx bx-edit-alt mr-1'></i>
                <i v-else @click="savePart(part)" class='bx bx-save mr-1'></i>
                <i @click="removePart(part)" class='bx bx-trash' style="color: red"></i>
              </template>
            </div>
          </b-td>
        </b-tr>
        <b-tr v-if="position!==1">
          <b-td :style="{left: getLeftPosition('info-line')}" class="subheader parts-footer text-right info-line"
                :colspan="isExistListColumn?5:4">Pricing Totals <span
              class="subheader-border-right"></span></b-td>
          <b-td class="totals-value text-center"
                style="position: relative; z-index: 1;"
                v-for="supplier in supplierPrice.orderedSuppliers" :key="supplier.id"
                :class="{'part-list-item-price': !supplier.priceSelected, 'supplier-column-highlight': isActiveColumn(supplier.id)}"
          >
            <div v-if="supplier.isRfqToSupplierStatusDecline" class="text-center decline-color">
              <span v-if="isDirectOrder">Declnd</span>
            </div>
            <div v-else class="total-price-number-position">{{ supplier.priceTotalCalc | formatMoney }}</div>
          </b-td>
        </b-tr>
        <b-tr v-if="position!==1">
          <b-td :style="{left: getLeftPosition('info-line')}" class="subheader parts-footer text-right info-line"
                :colspan="isExistListColumn?5:4">
            <span v-if="status === 'Priced' || status === 'Submitted'">Quote Valid Till</span>
            <span v-else> Parts Delivery ETA</span>
            <span class="subheader-border-right"></span></b-td>
          <b-td class="footer-value text-center"
                style="position: relative; z-index: 1;"
                v-for="supplier in supplierPrice.orderedSuppliers" :key="supplier.id"
                :class="{'part-list-item-price': !supplier.priceSelected, 'supplier-column-highlight': isActiveColumn(supplier.id)}"
          >
            {{ getDeliveryETA(supplier) }}
          </b-td>
        </b-tr>
        <!-- <b-tr v-if="position!==1">
          <b-td class="subheader parts-footer text-right" :colspan="isExistListColumn?5:4">Discount Applied<span
              class="subheader-border-right"></span></b-td>
          <b-td class="footer-value text-center"
                v-for="supplier in supplierPrice.orderedSuppliers" :key="supplier.id"
                :class="{'part-list-item-price': !supplier.priceSelected}"
          >{{
              supplier.discount | percentage(2)
            }}
          </b-td>
        </b-tr> -->
        <b-tr v-if="position!==1">
          <b-td :style="{left: getLeftPosition('info-line')}" class="subheader parts-footer text-right info-line"
                :colspan="isExistListColumn?5:4">Profit Breakdown<span
              class="subheader-border-right"></span></b-td>
          <b-td class="footer-value text-center"
                style="position: relative; z-index: 1;"
                v-for="supplier in supplierPrice.orderedSuppliers" :key="supplier.id"
                :class="{'part-list-item-price': !supplier.priceSelected, 'supplier-column-highlight': isActiveColumn(supplier.id)}"
          >
            <div v-if="supplier.isRfqToSupplierStatusDecline" class="text-center decline-color">
              <span v-if="isDirectOrder">Declnd</span>
            </div>
            <div v-else class="total-price-number-position">
              {{ supplier.priceSavingSelected | formatMoney }}
            </div>
          </b-td>
        </b-tr>
        <b-tr v-if="status === 'Ordered' ">
          <b-td class="subheader parts-footer text-right" :colspan="isExistListColumn?5:4">Order Subtotal<span
              class="subheader-border-right"></span></b-td>
          <b-td class="totals-value text-center"
                v-for="supplier in supplierPrice.orderedSuppliers" :key="supplier.id"
                :class="{'part-list-item-price': !supplier.orderTotal, 'supplier-column-highlight': isActiveColumn(supplier.id)}"
          >
            <div v-if="supplier.isRfqToSupplierStatusDecline" class="text-center decline-color">
              <span v-if="isDirectOrder">Declnd</span>
            </div>
            <div v-else class="total-price-number-position">
              {{ supplier.orderTotal | formatMoney }}
            </div>
          </b-td>
        </b-tr>
        <b-tr v-else-if="position!==1">
          <b-td :style="{left: getLeftPosition('info-line')}" class="info-line subheader parts-footer text-right"
                :colspan="isExistListColumn?5:4">Order Subtotal<span
              class="subheader-border-right"></span></b-td>
          <b-td class="totals-value text-center"
                style="position: relative; z-index: 1;"
                v-for="supplier in supplierPrice.orderedSuppliers" :key="supplier.id"
                :class="{'part-list-item-price': !supplier.priceSelected, 'supplier-column-highlight': isActiveColumn(supplier.id)}"
          >
            <div v-if="supplier.isRfqToSupplierStatusDecline" class="text-center decline-color">
              <span v-if="isDirectOrder">Declnd</span>
            </div>
            <div v-else-if="isRFQSubmitted" class="total-price-number-position">
              {{ supplier.priceTotalCalc  | formatMoney }}
            </div>
            <div v-else class="total-price-number-position">
              {{ supplier.priceSelectedWithDiscount | formatMoney }}
            </div>
          </b-td>
        </b-tr>

        <b-tr v-if="status === 'Ordered' ">
          <b-td class="subheader parts-footer text-right" :colspan="isExistListColumn?5:4">Order Subtotal<span
              class="subheader-border-right"></span></b-td>
          <b-td class="totals-value text-center"
                v-for="supplier in supplierPrice.orderedSuppliers" :key="supplier.id"
                :class="{'part-list-item-price': !supplier.orderTotal, 'supplier-column-highlight': isActiveColumn(supplier.id)}"
          >
            <div v-if="supplier.isRfqToSupplierStatusDecline" class="text-center decline-color">
              <span v-if="isDirectOrder">Declnd</span>
            </div>
            <div v-else-if="isRFQSubmitted" class="total-price-number-position">
              {{ supplier.orderTotal * 1.1  | formatMoney }}
            </div>
          </b-td>
        </b-tr>
        <b-tr v-else-if="position!==1">
          <b-td :style="{left: getLeftPosition('info-line')}" class="subheader parts-footer text-right info-line"
                :colspan="isExistListColumn?5:4">Grand Total (Incl
            GST)<span
                class="subheader-border-right"></span></b-td>
          <b-td class="totals-value text-center"
                style="position: relative; z-index: 1;"
                v-for="supplier in supplierPrice.orderedSuppliers" :key="supplier.id"
                :class="{'part-list-item-price': !supplier.priceSelected, 'supplier-column-highlight': isActiveColumn(supplier.id)}"
          >
            <div v-if="supplier.isRfqToSupplierStatusDecline" class="text-center decline-color">
              <span v-if="isDirectOrder">Declnd</span>
            </div>
            <div v-else-if="isRFQSubmitted" class="total-price-number-position">
              {{ supplier.priceTotalCalc * 1.1 | formatMoney }}
            </div>
            <div v-else class="total-price-number-position">
              {{ supplier.priceSelectedWithDiscount * 1.1 | formatMoney }}
            </div>
          </b-td>
        </b-tr>
        <b-tr v-if="position!==1 && position!==2">
          <template v-if="status === 'Ordered'">
            <b-td :style="{left: getLeftPosition('info-line')}" class="subheader parts-footer text-right info-line"
                  :colspan="isExistListColumn?5:4">
              Order number link
              <span class="subheader-border-right"></span>
            </b-td>
            <b-td class="footer-value text-center"
                  style="position: relative; z-index: 1;"
                  v-for="supplier in supplierPrice.orderedSuppliers"
                  :key="supplier.id">
              <b-button class="order"
                        :to="'/r/order/' + supplier.orderId"
                        v-if="supplier.orderNumber"
                        variant="link">
                {{ supplier.orderNumber }}
              </b-button>
            </b-td>
          </template>

<!--          commented by #600.3-->
<!--          <template v-else>-->
<!--            <b-td :style="{left: getLeftPosition('info-line')}" class="subheader parts-footer text-right info-line"
                  :colspan="isExistListColumn?5:4">Order Whole Parts-->
<!--              List<span-->
<!--                  class="subheader-border-right"></span></b-td>-->
<!--            <b-td class="footer-value text-center"-->
<!--                  style="position: relative; z-index: 1;"-->
<!--                  :class="{'supplier-column-highlight': isActiveColumn(supplier.id),}"-->
<!--                  v-for="supplier in supplierPrice.orderedSuppliers" :key="supplier.id">-->
<!--              <button v-if="!supplier.isRfqToSupplierStatusDecline"-->
<!--                      class="btn btn-primary order"-->
<!--                      @click="orderCreate(supplier)"-->
<!--                      :class="{'ordered-button': supplier.priceSelected && position!==3,-->
<!--                                'error-order': isQuoteExpired(supplier),-->
<!--                                'd-flex': isQuoteExpired(supplier),-->
<!--                              'not-active-button': supplier.priceSelected===0 && position===3,-->
<!--                              'green-order': isAvailableOrderParts && !(supplier.priceSelected===0 && position===3) && !(supplier.priceSelected===0 && position>3) && !(supplier.priceSelected && position!==3),-->
<!--                              'gray-button': supplier.priceSelected===0 && position>3}"-->
<!--              >-->
<!--                <span :class="{ 'error-order-padding': isQuoteExpired(supplier)}">-->
<!--                  {{ (supplier.priceSelected > 0 && position > 3) ? 'Ordered' : 'Order' }}-->
<!--                </span>-->
<!--                <span v-if="isQuoteExpired(supplier)" style="margin-top: 2px;">-->
<!--                  <i style="font-size: 16px;"-->
<!--                     v-b-tooltip.hover="{customClass: 'ps-tooltip', html: true, title: 'Quote Expired by the Supplier', placement:'leftbottom'}"-->
<!--                     class="bx bx-error-alt"></i>-->
<!--                </span>-->
<!--              </button>-->
<!--            </b-td>-->
<!--          </template>-->
        </b-tr>
        <b-tr v-if="position!==1" id="last-table-line">
          <b-td :style="{left: getLeftPosition('info-line')}" class="info-line subheader parts-footer text-right"
                :colspan="isExistListColumn?5:4">Your Rewards<span
              class="subheader-border-right"></span></b-td>
          <b-td class="footer-value text-center"
                style="position: relative; z-index: 1;"
                :class="{'part-list-item-price': !supplier.priceSelected, }"
                v-for="supplier in supplierPrice.orderedSuppliers" :key="supplier.id">{{
              supplier.reward
            }}
          </b-td>
        </b-tr>
      </b-tbody>
    </b-table-simple>

    <div
        class="topscroll"
        style="bottom: 0px; position: fixed; width: 600px; height: 5px; overflow-x: scroll; z-index: 5; background: white"
    >
      <div style="height: 1px" class="fake"></div>
    </div>
    <b-modal ref="viewdPartPhotoModal" @hide="onCloseModal" id="view-photo-modal" size="custom-view-part-photo"
             title="View Photo">
      <template v-slot:modal-backdrop class="my-backdrop"></template>
      <div class="modal-view-part-photo form-container">
        <form>
          <div class="pr-3 pl-3">
            <div class="pl-0 pb-3 font-weight-bold">View Photo for</div>
            <div>
              <p>Part Name: {{ selectedPartForModal && selectedPartForModal.name ? selectedPartForModal.name : '' }}</p>
              <p>Part Number:
                {{ selectedPartForModal && selectedPartForModal.partNumber ? selectedPartForModal.partNumber : '' }}</p>
            </div>
            <img
                :src="selectedPartForModal && selectedPartForModal.photoForPart && selectedPartForModal.photoForPart.id ? selectedPartForModal.photoForPart.url : ''"
                style="height: 550px" alt='Part photo' class="w-100 mb-3">
          </div>
        </form>
      </div>
      <template v-slot:modal-footer="{ cancel }">
        <template>
          <b-button class="input-btn cancel mr-2" @click="cancel()">Close</b-button>
        </template>
      </template>
    </b-modal>

    <b-modal ref="importPdfPricingModal" @hide="onCloseImportModal" id="importPdfPricingModal" size="custom-view-part-photo repairer-import-pfd-pricing-modal"
             title="Import PDF Pricing">
      <template v-slot:modal-backdrop class="my-backdrop"></template>
      <div class="modal-view-part-photo form-container">
        <form>
          <div class="pr-3 pl-3">
            <div :class="{'hidden-vue-dropzone': isLoading}" v-if="!isHideDropzone" class="import-pdf-loader">
              <vue-dropzone
                  v-on:vdropzone-complete="completeFileUpload"
                  v-on:vdropzone-sending="sendFileUpload"
                  v-on:vdropzone-success="successFileUpload"
                  v-on:vdropzone-error="errorFileUpload"
                  ref="PDFUpDrop"
                  id="PDFUploadDrop"
                  class="dropify-wrapper dropzoneContainer"
                  :options="PDFUploadDropzoneOptions"
                  :useCustomSlot="true">
                <div class="dropify-message" style="transform: translateY(0%); min-width: 125px;">
                  <div class="dropzone-block">
                    <i class='bx bxs-file-pdf'></i>
                    <i class='bx bx-cloud-upload'></i>
                    <p class="dnd-title">Drag and Drop File Here</p>
                    <p class="text-or mb-0-5 dnd-or">Or</p>
                  </div>
                  <div class="text-sm-center mb-1 ex-image-upload-buttons">
                    <button id="clickBrowsePDFBtn" type="button"
                            class="btn btn-primary waves-effect waves-light browse-btn browse-btn-files">Browse Files
                    </button>
                  </div>
                </div>
              </vue-dropzone>
            </div>
            <div v-if="isLoading" style="margin-top: 20px;">
              <div class="pdf-upload-loader-wrapper">
                <div class="pdf-upload-loader">
                  <i class='bx bx-file'></i>
                  <b-progress :value="valueLoader" :max="maxLoader" height="100%" class="pdf-upload-progress"></b-progress>
                </div>
              </div>
            </div>

            <div v-if="isError && !isLoading && valueLoader === 100" style="margin-top: 20px">
              <div class="pdf-upload-loader-wrapper">
                <div class="pdf-upload-error">
                  <p>An <span>ERROR</span> has occurred converting your  PDF Quote</p>
                  <p>A support ticket has been raised.</p>
                  <p>Sorry, we will work on resolving so it doesn't happen again!</p>
<!--                  <button @click="closeWizard" type="button"-->
<!--                          class="btn btn-secondary waves-effect waves-light browse-btn browse-btn-files">Close-->
<!--                  </button>-->
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
      <template v-slot:modal-footer="{ cancel }">
        <template>
          <b-button class="input-btn cancel mr-2" @click="cancel()">Close</b-button>
        </template>
      </template>
    </b-modal>

    <vue-context ref="importPdfPricing"
                 :lazy="true"
                 style="width: 150px"
                 :close-on-click="false"
                 :closeOnScroll="false">
      <template slot-scope="item">
        <b-button-toolbar>
          <b-button-group style="width: 150px"  vertical class="parts-order-view__context-menu">
            <b-button
                v-if="isShowImportingPricing(item)"
                class="text-left font-12 font-inter--semibold"
                variant="outline-primary"
                @click="PDFUploadDropzoneOptions.params.rfqId = item.data.id; $refs.importPdfPricingModal.show(); $refs.importPdfPricing.close(); supplierContextId = item.data.id"
                block>
              Import PDF Pricing
            </b-button>
            <b-button
                v-if="isShowUndoImportingPricing(item) && !isShowImportingPricing(item) && !isShowUploadPdfUpdate(item)"
                class="text-left font-12 font-inter--semibold"
                variant="outline-primary"
                @click="undoImportPricing(item.data.id)"
                block>
              Undo Import Pricing
            </b-button>

            <b-button
                v-if="isShowUploadPdfUpdate(item)"
                class="text-left font-12 font-inter--semibold"
                variant="outline-primary"
                @click="PDFUploadDropzoneOptions.params.rfqId = item.data.id; $refs.importPdfPricingModal.show(); $refs.importPdfPricing.close(); supplierContextId = item.data.id"
                block>
              Upload PDF Update
            </b-button>

          </b-button-group>
        </b-button-toolbar>
      </template>
    </vue-context>
    <vue-context
        ref="menuContext"
    style="width: 165px">
      <template slot-scope="item">
        <b-button-toolbar key-nav>
          <b-button-group vertical class="parts-order-view__context-menu">
            <template >
              <b-button
                  v-if="item && item.data && (item.data.secondRequestBestPrice === false && rfq.secondRequestBestPrice === false) && (rfq.thirdRequestBestPrice !== true && rfq.thirdRequestBestPrice !== false)"
                  class="text-left font-12 font-inter--semibold"
                  variant="outline-primary"
                  block
                  @click="$emit('unlockRequestBestPrice', item.data.id)"
              > Unlock for BEST PRICE
              </b-button>
              <b-button
                  class="text-left font-12 font-inter--semibold"
                  v-if="item && item.data && item.data.thirdRequestBestPrice === true"
                  @click="$emit('lockRequestBestPrice', item.data.id)"
                  variant="outline-primary"
                  block
                  style="width:165px;"
              >Lock Pricing
              </b-button>
            </template>
          </b-button-group>
        </b-button-toolbar>
      </template>
    </vue-context>
  </div>
</template>

<script>
/*eslint-disable */

import _ from 'lodash'
import NumberFormatter from '../../../../components/utility/number-formatter'
import * as dayjs from 'dayjs'
import { mapGetters } from 'vuex'
import accounting from 'accounting'
import Axios from "axios";
import vue2Dropzone from 'vue2-dropzone'
import 'vue2-dropzone/dist/vue2Dropzone.min.css'
import tooltipOptions from './tooltipOptions'
import {VueContext} from 'vue-context'

var utc = require('dayjs/plugin/utc')
dayjs.extend(utc)

export default {
  name: 'RepairerPartList',
  mixins: [tooltipOptions],
  components: {
    VueContext,
    NumberFormatter,
    vueDropzone: vue2Dropzone,
  },
  props: {
    purchasePriceSelector: {
      type: Object,
      default: () => {}
    },
    rfqOrders: {
      type: Object,
      default: () => {}
    },
    supplierPrice: {
      type: Object,
      required: true
    },
    isDirectOrder: {
      type: Boolean,
      default: false,
    },
    isRFQReceived: {
      type: Boolean,
      default: false,
    },
    isPartListTab: {
      type: Boolean,
      default: false,
    },
    isRFQSubmitted: {
      type: Boolean,
      default: false,
    },
    isRFQPriced: {
      type: Boolean,
      default: false,
    },
    isRFQCancelled: {
      type: Boolean,
      default: false,
    },
    isAvailableOrderParts: {
      type: Boolean,
      default: false,
    },
    manualParts: {
      type: Array,
      default: () => []
    },
    rfqId: {
      type: String,
      default: () => ''
    },
    rfq: {
      type: Object,
      default: () => {}
    },
    status: {
      type: String,
      required: true
    },
    position: {
      type: Number,
      default: 1
    },
  },
  data () {
    return {
      supplierContextId: null,
      PDFUploadDropzoneOptions: {
        clickable: '#clickBrowsePDFBtn',
        resizeWidth: 1500,
        resizeQuality: 0.7,
        url:  appConfig.baseAPIURL + '/ir/repairer/rfq/' + this.rfqId + '/import-pdf',
        headers: { 'Authorization': localStorage.getItem('token') },
        method: 'post',
        params: {
          rfqId: null
        },
        maxFilesize: 20,
        thumbnailWidth: 200,
        timeout: 300000,
        addRemoveLinks: true,
        acceptedFiles: 'application/pdf',
        dictFileTooBig: 'Your file has exceeded the maximum limit of 20MB per file. Please reduce the file size and upload again',
      },
      isLoading: false,
      interval: null,
      valueLoader: 0,
      isLoadedParts: false,
      isError: false,
      maxLoader: 100,
      isHideDropzone: false,
      isClickedOnIcon: false,
      newPercentForTooltip: null,
      activeSupplierId: null,
      selectedPartForModal: null,
      isOpenedModal: false,
      isClickable: false,
      partFromSupplier: false,
      isEditPartList: false,
      leftPositions: {},
    }
  },
  computed: {
    ...mapGetters({
      'stockOptions': 'stockOptions'
    }),
    selectedParts () {
      let parts = []
      _.forEach(this.supplierPrice.orderedSuppliers, (s) => {
        _.forEach(s.selectedParts, (id) => {
          parts.push({
            partId: id,
            suppId: s.id,
          })
        })
      })
      return parts
    },
    OEMSupplier () {
      return this.supplierPrice.OEMSupplier
    },
    allParts () {
      console.log('allParts', this.supplierPrice.parts)
      return this.supplierPrice.parts//.concat(this.manualParts);
    },
    isHasAdditionalParts () {
      return !!_.filter(this.supplierPrice.parts, (p) => {
        return p.isAdditional
      }).length
    },
    isExistListColumn () {
      return this.OEMSupplier ? true : false
    }
  },
  methods: {
    isShowImportingPricing(item) {
      return item && item.data && (item.data.rfqToSupplierStatus == 'New' || item.data.rfqToSupplierStatus == 'Lost')
    },
    isShowUndoImportingPricing(item) {
      return item && item.data && item.data.rfqToSupplierStatus == 'Priced'
    },
    isShowUploadPdfUpdate(item) {
      return item && item.data && item.data.rfqToSupplierStatus == 'Priced' && this.isRFQPriced  && this.rfq.secondRequestBestPrice !== true && this.rfq.thirdRequestBestPrice !== true
    },
    isLockedSupplier(supplier) {
      return ((!supplier.isNonRegistered &&supplier.secondRequestBestPrice === false && supplier.thirdRequestBestPrice !== true && supplier.thirdRequestBestPrice !== false)
                || (!supplier.isNonRegistered && supplier.thirdRequestBestPrice === false)
                || (supplier.isNonRegistered && supplier.secondRequestBestPrice === true))
                || (supplier.isNonRegistered && (this.rfq.secondRequestBestPrice === true || this.rfq.thirdRequestBestPrice === true))
    },
    isUnLockedSupplier(supplier) {
      return ((!supplier.isNonRegistered && supplier.secondRequestBestPrice === true && supplier.thirdRequestBestPrice !== true && supplier.thirdRequestBestPrice !== false)
                ||(!supplier.isNonRegistered && supplier.thirdRequestBestPrice === true)
                || (supplier.isNonRegistered && (this.rfq.secondRequestBestPrice === false && (this.rfq.thirdRequestBestPrice !== true &&  this.rfq.thirdRequestBestPrice !== false)))
      )
    },
    undoImportPricing(suppId) {
      this. $refs.importPdfPricing.close()
      Axios.post(`/ir/repairer/rfq/${this.rfqId}/undo-modification-parts`, {
        rfqId: suppId
      })
    },
    setFakeScroll() {
      if (this.isRFQReceived) {
        return
      }
      let container = $('.repairer-rfq-view-part-list-container')
      let topscroll = $('.topscroll')

      $('.fake').width($('.repairer-rfq-view-part-list-table-container-2').width())
      $('.topscroll').width($('.repairer-rfq-view-part-list-container').width())

      // let div = document.getElementsByClassName('repairer-rfq-view-part-list-container')?.[0];
      //

      if (document.getElementById('last-table-line').getBoundingClientRect().bottom + 13 <= window.innerHeight) {
        $('.topscroll').hide()
      } else {
        $('.topscroll').show()
      }

      topscroll.scroll(function (e) {
        container.scrollLeft($(this).scrollLeft())
      })
      container.scroll(function (e) {
        topscroll.scrollLeft($(this).scrollLeft())
      })
    },
    keyDownEvent (e) {
      if (!this.isPartListTab) {
        return
      }
      let el = document.getElementById('rfq-view-table')
      if (!el) {
        return
      }
      if (e.code == 'ArrowRight') {
        el.scrollLeft += 15
      } else if (e.code == 'ArrowLeft') {
        el.scrollLeft -= 15
      }
    },
    setLeftPosition () {
      this.setFakeScroll()
      let lineWidth = document.getElementById('rfq-view-line-column').clientWidth
      let partListWidth = document.getElementById('rfq-view-part-list-column').clientWidth
      let partNumberWidth = document.getElementById('rfq-view-part-number-column').clientWidth
      let qtyWidth = document.getElementById('rfq-view-qty-column').clientWidth
      // let rrpWidth = document.getElementById('rfq-view-rrp-column').clientWidth;

      this.$nextTick(() => {
        this.$set(this.leftPositions, 'rfq-view-line-column', '0px')
        this.$set(this.leftPositions, 'rfq-view-part-list-column', lineWidth + 'px')
        this.$set(this.leftPositions, 'rfq-view-part-number-column', lineWidth + partListWidth + 'px')
        this.$set(this.leftPositions, 'rfq-view-qty-column', lineWidth + partListWidth + partNumberWidth + 'px')
        this.$set(this.leftPositions, 'rfq-view-rrp-column', lineWidth + partListWidth + partNumberWidth + qtyWidth + 'px')
        this.$set(this.leftPositions, 'info-line', '0px')
      })
    },
    getLeftPosition (type) {
      if (this.leftPositions[type]) {
        return this.leftPositions[type]
      }
      return '0px'
    },
    openContextMenu(supplier, e)  {
      if ((this.isRFQSubmitted ||
          (this.isRFQPriced && supplier.rfqToSupplierStatus == 'Priced' && this.rfq.secondRequestBestPrice !== false && this.rfq.secondRequestBestPrice !== true && this.rfq.thirdRequestBestPrice !== true && this.rfq.thirdRequestBestPrice !== false) ||
          (this.isRFQPriced && this.rfq.secondRequestBestPrice === false && this.rfq.thirdRequestBestPrice !== true && this.rfq.thirdRequestBestPrice !== false)) && supplier.isNonRegistered) {
       this.$refs.importPdfPricing.open(e, supplier)
      } else if (!supplier.isNonRegistered && this.isRFQPriced && this.rfq.secondRequestBestPrice === false && supplier.secondRequestBestPrice === false && this.rfq.thirdRequestBestPrice !== false) {
        this.$refs.menuContext.open(e, supplier)
      } else if (supplier.isNonRegistered && this.isRFQPriced){
        this.$refs.importPdfPricing.open(e, supplier)
      }
    },
    onCloseImportModal() {
      this.valueLoader = 0;
      this.isLoading = false
      clearInterval(this.interval)
      this.isLoadedParts = false
      this.isError = false
      this.isHideDropzone = false
    },
    clickedOnIcon (offer, supplier) {
      if (!this.isShowSimpleTooltip(offer, supplier)) {
        this.isClickedOnIcon = true
      }
    },
    unBlinkPart (id) {
      let ids = []
      if (id == -1) {
        _.forEach(this.allParts, (p) => {
          ids.push(p.id)
        })
      } else {
        ids.push(id)
      }
      if (!_.isEmpty(ids)) {
        Axios.post('/ir/repairer/rfq/' + this.rfqId, {
          viewedParts: ids
        })
            .then(response => {
              if (response.data._status) {
                _.forEach(ids, (id) => {
                  let part = _.find(this.allParts, (p) => {
                    return Number(p.id) === Number(id)
                  })
                  if (part) {
                    part.isNotViewed = false
                  }
                })
                this.$root.$emit('bv::hide::tooltip')
              }
            })
      }
    },
    isBlinkingGreen (id) {
      if (id == -1) {
        return !_.isEmpty(this.OEMSupplier) && this.OEMSupplier.rfqToSupplierStatus === 'New' && this.isRFQSubmitted && _.filter(this.allParts, (prt) => {
          return prt.numberBySupplier !== null && prt.rrpBySupplier !== null && prt.isNotViewed
        }).length
      } else {
        let part = _.find(this.allParts, (prt) => {
          return Number(prt.id) === Number(id)
        })
        return !_.isEmpty(this.OEMSupplier) && this.OEMSupplier.rfqToSupplierStatus === 'New' && this.isRFQSubmitted
            && part && part.numberBySupplier !== null && part.rrpBySupplier !== null && part.isNotViewed
      }
    },
    isPartNameGray (part) {
      if (this.isRFQReceived && this.isHasAdditionalParts) {
        return part.isAdditional
      } else {
        return !part.isOriginalPart
      }
    },

    isShowComment (supplier, part, isManual = false) {
      if (isManual) {
        return this.isShowConditionIcon(supplier, part, true) || _.trim(supplier.parts[0][part.id].stockComment) && (supplier.parts[0][part.id].stock === 1 || supplier.parts[0][part.id].stock === 6)
      }
      return this.isShowConditionIcon(supplier, part) || _.trim(supplier.getOffer(part).stockComment) && (part.stock[supplier.id] === 1 || part.stock[supplier.id] === 6)
    },
    setActiveSupplier (id) {
      if (!this.isRFQPriced) {
        return
      }
      this.activeSupplierId = id
    },
    isActiveColumn (id) {
      if (!this.isRFQPriced) {
        return
      }
      return id === this.activeSupplierId
    },
    isSelectedAllParts (supplier) {
      console.log('isSelectedAllParts1', supplier)
      return supplier && supplier.hasOwnProperty('getPartsLength') && supplier?.selectedParts?.length === supplier.getPartsLength()
    },
    isShowImgIcon (supplier, part) {
      return !!supplier?.getPart(part?.id)?.photoForPart?.id
    },
    isShowConditionIcon (supplier, part, isDelivery = false) {
      if (isDelivery) {
        return !!_.trim(supplier?.parts?.[0]?.[part.id]?.stockComment)
      }
      return !!_.trim(supplier?.getPart(part?.id)?.condition)
    },
    openViewPhotoModal (part) {
      console.log('click 2')
      if (part.photoForPart && part.photoForPart.id) {

        part = _.cloneDeep(part)

        if (this.isRFQSubmitted && this.getPartNumberForSubmitted(part.id)) {
          part.partNumber = this.getPartNumberForSubmitted(part.id)
        } else {
          if (part.number === this.getPartNumber(part.id) || _.isEmpty(this.getPartNumber(part.id))) {
            part.partNumber = part.number
          } else {
            part.partNumber = this.getPartNumber(part.id)
          }
        }
        let originalPart = _.find(this.allParts, (prt) => {
          return Number(prt.id) === Number(part.originalPartId)
        })
        if (originalPart) {
          part.name = originalPart.name
        }
        this.selectedPartForModal = part
        this.isOpenedModal = true
        this.$refs['viewdPartPhotoModal'].show()
      }
    },
    errorFileUpload () {
      // this.interval = setInterval(() => {
      //   if (this.valueLoader === 100) {
      //     this.isError = true
      //     setTimeout(() => {
      //       this.isLoading = false
      //       this.isHideDropzone = true
      //     }, 1000)
      //     clearInterval(this.interval)
      //   }
      // }, 500)

      this.isError = true
      // setTimeout(() => {
        this.isLoading = false
        this.isHideDropzone = true
      this.valueLoader = 100
      // }, 1000)
      clearInterval(this.interval)
    },
    completeFileUpload ($event) {
      $event.previewElement.children[($event.previewElement.children.length - 1)].removeAttribute('style')
    },
    startLoading () {
      this.isLoading = true
      this.interval = setInterval(() => {
        if (!this.isLoading) {
          this.isHideDropzone = true
          clearInterval(this.interval)
        } else if (this.valueLoader === 100 && (this.isLoadedParts || this.isError)) {
          this.valueLoader = 100
          setTimeout(() => {
            this.isLoading = false
            this.isHideDropzone = true
          }, 1000)
          clearInterval(this.interval)
        } else if (this.valueLoader !== 100) {
          this.valueLoader += 2
        }
      }, 100)
    },
    sendFileUpload ($event) {
      this.startLoading()
      $event.previewElement.children[($event.previewElement.children.length - 1)].style.display = 'none'
    },
    successFileUpload (file, response) {
      if (response._status) {
        this.isLoadedParts = true
        this.onCloseImportModal()
        this.$refs.importPdfPricingModal.hide();
        // let data  = {
        //   parts: [],
        //   rfqId: this.supplierContextId
        // }
        // console.log(response.data.parts, 'result', this.allParts)
        // _.forEach(response.data.parts, (prt, index) => {
        //   let partIndex = index + 1 > this.allParts.length ? -1 : index
        //   if (partIndex !== -1) {
        //     let suppIndex = _.findIndex(this.supplierPrice.orderedSuppliers, (s) => {
        //       return Number(s.id) === Number(this.supplierContextId)
        //     })
        //     if (prt.rrp !== null && suppIndex !== -1 && this.supplierPrice.orderedSuppliers[suppIndex].type === 'OEM-GEN') {
        //       this.allParts[partIndex].rrp = prt.rrp;
        //     }
        //     if (suppIndex !== -1) {
        //       let offerInd = _.findIndex(this.supplierPrice.orderedSuppliers[suppIndex].offers, (offer) => {
        //         return Number(offer.originalPartId) == Number(this.allParts[partIndex].id)
        //       })
        //       if (offerInd !== -1) {
        //         if (this.supplierPrice.orderedSuppliers[suppIndex].offers[offerInd].firstPrice == null) {
        //           this.supplierPrice.orderedSuppliers[suppIndex].offers[offerInd].firstPrice = prt.price;
        //         }
        //         this.supplierPrice.orderedSuppliers[suppIndex].offers[offerInd].price = prt.price;
        //
        //         if (this.supplierPrice.orderedSuppliers[suppIndex].offers[offerInd].firstRrp == null) {
        //           this.supplierPrice.orderedSuppliers[suppIndex].offers[offerInd].firstRrp = prt.rrp;
        //         }
        //
        //         this.supplierPrice.orderedSuppliers[suppIndex].offers[offerInd].rrp = prt.rrp;
        //         let obj = {
        //           id: this.supplierPrice.orderedSuppliers[suppIndex].offers[offerInd].id,
        //           price: prt.price,
        //         }
        //         if (prt.rrp) {
        //           obj.rrp = prt.rrp
        //         }
        //         data.parts.push(obj)
        //       }
        //     }
        //   }
        // })

        // if (!_.isEmpty(data.parts)) {
        //   Axios.post('/ir/repairer/rfq/modification-parts', data)
        //       .then(response => {
        //         if (response.data._status) {
        //         }
        //       })
        // }
        this.$emit('importedParts', {parts: response.data.parts, file: response.data, id: this.supplierContextId, pdfFile: response.pdfFile})
        this.$emit('showImportWizard')
      } else {
        this.isError = true
        this.errorFileUpload()
      }
      console.log(file, response)
   },
    onCloseModal () {
      console.log('on close')
      this.isOpenedModal = false
      this.selectedPart = {}
      this.photosForModal = []
    },
    isHasNonReturnable (supp) {
      return !!supp
    },
    isQuoteExpired (supp) {
      let flag = false
      if (supp.quoteValidTill && this.status === 'Priced') {
        let day1 = dayjs(supp.quoteValidTill)
        let day2 = dayjs.utc()
        let diff = day1.diff(day2, 'day')
        // if (diff <= 0) {
        //   flag = true;
        // }
        if (day1 < day2) {
          flag = true
        }
      }
      return flag
    },
    getDeliveryETA (supp) {
      if (supp.quoteValidTill && this.status === 'Priced') {
        // let day1 = dayjs(supp.quoteValidTill);
        // let day2 = dayjs(new Date());
        // let diff = day1.diff(day2, 'day');
        //
        // if (diff > 0) {
        //   return diff;
        // }
        return dayjs(supp.quoteValidTill).format('DD/MM/YYYY')
      }

      return supp.partsDeliveryETA
    },
    isOffer (part) {
      let result = false
      for (let id in this.supplierPrice.orderedSuppliers) {
        let supplier = this.supplierPrice.orderedSuppliers[id]
        if (this.supplierPrice.isOfferOrdered(supplier.getOffer(part))) {
          result = true
        }
      }
      return result
    },
    getTooltipForOrderedPart (part) {
      let result = 'Ordered'
      if (this.getOrderedParentNumber(part)) {
        result += `<br>` + 'Ordr Nbr: ' + this.getOrderedParentNumber(part)
      }
      if (this.getOrderedParentDate(part)) {
        result += `<br>` + this.getOrderedParentDate(part)
      }
      return result
    },
    getOrderedParentNumber (part) {
      let result = false
      for (let id in this.supplierPrice.orderedSuppliers) {
        let supplier = this.supplierPrice.orderedSuppliers[id]
        if (this.supplierPrice.isOfferOrdered(supplier.getOffer(part))) {
          let offer = _.find(this.supplierPrice.partsOrdered, (prt) => {
            return Number(prt.offerId) === Number(supplier.getOffer(part).id)
          })
          if (offer) {
            _.forIn(this.rfqOrders, (order) => {
              let orderedPart = _.find(order.partsOrdered, (p) => {
                return Number(p.id) === Number(offer.id)
              })
              if (orderedPart) {
                result = order.number
                return
              }
            })
          }
        }
      }
      return result
    },
    getOrderedParentDate (part) {
      let result = false
      for (let id in this.supplierPrice.orderedSuppliers) {
        let supplier = this.supplierPrice.orderedSuppliers[id]
        if (this.supplierPrice.isOfferOrdered(supplier.getOffer(part))) {
          let offer = _.find(this.supplierPrice.partsOrdered, (prt) => {
            return Number(prt.offerId) === Number(supplier.getOffer(part).id)
          })
          if (offer) {
            result = dayjs(offer.orderedOn).format('hh:mmA || DD/MM/YYYY')
          }
        }
      }
      return result
    },
    isEmpty (value) {
      return _.isEmpty(value)
    },
    getIsNeedShowPartPrice (supp, prt) {
      return !!((prt.isNeedsOEMGEN && (supp.isSupplierTypeOEMGEN))
          || (prt.isNeedsOEMPAR && supp.isSupplierTypeOEMPAR)
          || (prt.isNeedsOEMALT && supp.isSupplierTypeOEMAlt)
          || (prt.isNeedsAFTM && supp.isSupplierTypeAFTM)
          || (prt.isNeedsCERTAFTM && supp.isSupplierTypeCERTAFTM)
          || (prt.isNeedsRECO && supp.isSupplierTypeRECO)
          || (prt.isNeedsUSED && supp.isSupplierTypeUSED))
    },
    getCommentTooltip (supplier, part, isDelivery = false) {
      let comment = ''
      if (isDelivery) {
        let stockComment = _.trim(supplier?.parts?.[0]?.[part.id]?.stockComment)
        let condition = _.trim(supplier?.parts?.[0]?.[part.id]?.condition)

        if (stockComment) {
          comment += stockComment
        }
        if (condition) {
          if (stockComment) {
            comment += '<br/>'
          }
          comment += `Condition: ${condition}`
        }
      } else {
        let stockComment = _.trim(supplier?.getOffer(part)?.stockComment)
        let condition = _.trim(supplier?.getPart(part?.id)?.condition)

        if (stockComment) {
          comment += stockComment
        }
        if (condition) {
          if (stockComment) {
            comment += '<br/>'
          }
          comment += `Condition: ${condition}`
        }
      }
      return comment
    },

    getCombinedCostPrice(supplier, prt) {
      let priceWithLinked = parseFloat(prt.price)
      _.forIn(supplier.parts, (p, pId) => {
        if (Number(pId) === 0) {
          _.forIn(p, (mP) => {
            if (Number(mP.linkId) > 0 && mP.linkType === 'withCost' && Number(mP.linkId) === Number(prt.id)) {
              priceWithLinked += parseFloat(supplier.getOffer(mP).purchasePriceFigures?.costPrice)
            }
          })
        } else if (Number(p.linkId) > 0 && p.linkType === 'withCost' && Number(p.linkId) === Number(prt.id)) {
          priceWithLinked += parseFloat(supplier.getOffer(p).purchasePriceFigures?.costPrice)
        }
      })
      return priceWithLinked
    },

    getTooltipTextForTriagle(prt, supplier) {
      if (this.isGreenTriangle(prt, supplier)) {
        return 'Suppliers OE reference nbr matches'
      } else if (this.isDarkGreyTriangle(prt, supplier)) {
        return 'OE reference nbr not matching'
      }
      return ''
    },
    isGreenTriangle(prt, supplier) {
      if (_.isEmpty(this.OEMSupplier) || supplier.isSupplierTypeOEMGEN) {
        return false
      }

      // let origNbr = this.originalParts[ind]?.number ? _.trim(this.originalParts[ind]?.number).replaceAll(' ', '') : ''
      // let imprtNbr = this.importedParts2?.[ind]?.oeNumber ? _.trim(this.importedParts2?.[ind]?.oeNumber).replaceAll(' ', '') : ''
      // return this.importedParts2?.[ind]?.isMatched && origNbr && imprtNbr && _.includes(origNbr, imprtNbr)
      let oemComment = this.getPartNumber(prt.originalPartId)
      if (oemComment){
        oemComment = _.trim(oemComment).replaceAll(' ', '')
      }
      let oeComment = prt.oePartNumber
      if (oeComment){
        oeComment = _.trim(oeComment).replaceAll(' ', '')
      }
      if (!_.isEmpty(oemComment) && !_.isEmpty(oeComment) && _.includes(oemComment, oeComment)) {
        return true
      }
      return false
    },
    isDarkGreyTriangle(prt, supplier) {
      if (_.isEmpty(this.OEMSupplier) || supplier.isSupplierTypeOEMGEN) {
        return false
      }
      let oemComment = this.getPartNumber(prt.originalPartId)
      if (oemComment){
        oemComment = _.trim(oemComment).replaceAll(' ', '')
      }
      let oeComment = prt.oePartNumber
      if (oeComment){
        oeComment = _.trim(oeComment).replaceAll(' ', '')
      }
      if (!_.isEmpty(oemComment) && !_.isEmpty(oeComment) && !_.includes(oemComment, oeComment)) {
        return true
      }
return false
      // let oemComment = this.getPartNumber(prt.originalPartId)
      // let oeComment = prt.oePartNumber
      // if (!_.isEmpty(oemComment) && !_.isEmpty(oeComment) && _.trim(oemComment) !== _.trim(oeComment)) {
      //   return true
      // }
      // return false
    },
    closeAllTooltips (e) {
      if (e.key === 'Escape') {
        this.$root.$emit('bv::hide::tooltip')
      }
    },
    getCalculateInformation (offer, supplier) {
      // if (this.isShowSimpleTooltip(offer, supplier)) {
      //   return ''
      // }
      //
      // let stockComment = _.trim(offer?.stockComment)
      // let condition = _.trim(supplier?.getPart(offer?.id)?.condition)
      // let comment = ''
      //
      // if (stockComment) {
      //   comment += stockComment
      // }
      // if (condition) {
      //   if (stockComment) {
      //     comment += '<br/>'
      //   }
      //   comment += `Condition: ${condition}`
      // }
      //
      // let percent = null
      // if (offer.purchasePriceFigures?.listPrice > 0) {
      //   percent = accounting.toFixed((100 * offer.purchasePriceFigures.sellPrice) / offer.purchasePriceFigures.listPrice, 0)
      //   // if (this?.purchasePriceSelector?.listPricePercents?.hasOwnProperty('all')) {
      //   //   percent = this.purchasePriceSelector.listPricePercents.all
      //   //   percent = accounting.toFixed((100 * offer.purchasePriceFigures.sellPrice) / offer.purchasePriceFigures.listPrice, 2)
      //   // } else if (this?.purchasePriceSelector?.listPricePercents?.hasOwnProperty(_.toLower(supplier.type))) {
      //   //   percent = this.purchasePriceSelector.listPricePercents[_.toLower(supplier.type)]
      //   //   percent = accounting.toFixed((100 * offer.purchasePriceFigures.sellPrice) / offer.purchasePriceFigures.listPrice, 2)
      //   // }
      // }
      // if (!_.isEmpty(this.purchasePriceSelector?.display) && !_.isEmpty(offer.purchasePriceFigures)) {
      //   let stock = _.find(this.stockOptions, (s) => {
      //     return s?.value === offer?.stock
      //   })
      //
      //   let costPricePercentage = accounting.toFixed((100 * offer.purchasePriceFigures.profitValue) / offer.purchasePriceFigures?.costPrice, 2)
      //   if (costPricePercentage < 0) {
      //     costPricePercentage = accounting.toFixed(Math.abs(costPricePercentage), 2)
      //   }
      //
      //   let result = `<div class="tooltip-each-price-information-block">`
      //   result += `<div class="d-flex">
      //                   <p class="col-4 p-0 text-left font-weight-600">Part Number </p>
      //                   <p class="col-8 text-left p-0">${this.getPartNumber(offer.originalPartId)}</p>
      //                 </div>`
      //
      //   if (this.purchasePriceSelector.display.stockStatus) {
      //     result += `<div class="d-flex">
      //                   <p class="col-4 text-left p-0 font-weight-600">Stock Status </p>
      //                   <p class="col-8 text-left p-0">${stock?.label}</p>
      //                </div>`
      //     if (!_.isEmpty(comment)) {
      //       result += `<div class="d-flex">
      //                   <p class="">${comment}</p>
      //                </div>`
      //     }
      //   }
      //   if (this.purchasePriceSelector.display.listPrice) {
      //     result += `<div class="d-flex align-items-center mb-2">
      //                   <p class="col-4 text-left p-0 mb-0 font-weight-600">List Price</p>
      //                   <b-form-input disabled class="col-8 p-0 form-control tooltip-each-price-information-input font-weight-normal">${this.formatValue(offer.purchasePriceFigures.listPrice)}</b-form-input>
      //                </div>`
      //   }
      //   if (this.purchasePriceSelector.display.sellPrice) {
      //     result += `<div class="d-flex align-items-center mb-2">
      //                   <p class="col-4 text-left p-0 mb-0 font-weight-600">Sell Price</p>
      //                   <b-form-input disabled class="col-4 p-0 form-control tooltip-each-price-information-input font-weight-normal">${this.formatValue(offer.purchasePriceFigures.sellPrice)}</b-form-input>
      //                   `
      //     if (percent && !offer.purchasePriceFigures.editMode) {
      //       result += ` <p class="col-4 text-left p-0 mb-0 pl-3 font-weight-600">${percent}% of List</p>`
      //     } else if (offer.purchasePriceFigures.editMode) {
      //       result += ` <p class="col-4 text-left p-0 mb-0 pl-3 font-weight-600">
      //                   <input id="${'percent-' + offer.id}" style="padding-left5px; padding-right: 5px; width:32px; max-width: 32px; min-width: 32px; display: inline-block;" class="form-control tooltip-each-price-information-input font-weight-normal "/>% of List</p>`
      //     }
      //
      //     result += `</div>`
      //   }
      //
      //   if (this.purchasePriceSelector.display?.costPrice) {
      //     result += `<div class="d-flex mt-3 mb-2">
      //                   <p class="col-4 text-left p-0"></p>
      //                   <p class="col-4 text-left p-0 pb-2 tooltip-each-price-information-line font-weight-600">Purchase Price</p>
      //                 `
      //
      //     if (this.purchasePriceSelector.display.markUpPercentage) {
      //       result += `<p class="col-4 text-left p-0 pb-2 pl-3 tooltip-each-price-information-line font-weight-600">Markup</p>`
      //     }
      //     result += ` </div>`
      //
      //     result += `<div class="d-flex align-items-center mb-2">
      //                   <p class="col-4 text-left p-0 mb-0 font-weight-900">Cost Price</p>
      //                   <b-form-input disabled class="col-4 p-0 form-control tooltip-each-price-information-input font-weight-900">${this.formatValue(offer.purchasePriceFigures?.costPrice)}</b-form-input>
      //                  `
      //
      //     if (this.purchasePriceSelector.display.markUpPercentage) {
      //       result += ` <p class="col-4 text-left p-0 mb-0 pl-3 font-weight-900`
      //       if (Number(costPricePercentage) < Number(this.purchasePriceSelector.listPricePercents?.markUp)) {
      //         result += ` color-red `
      //       } else {
      //         result += ` color-green `
      //       }
      //       result += `">${costPricePercentage}%</p>`
      //     }
      //
      //     result += `</div>`
      //
      //     if (this.purchasePriceSelector.display.profitValue) {
      //       result += `<div class="d-flex align-items-center mb-2">
      //                   <p class="col-4 text-left p-0 mb-0 font-weight-600">Profit Value</p>
      //                   <b-form-input disabled class="col-8 p-0 form-control tooltip-each-price-information-input font-weight-normal">${this.formatValue(offer.purchasePriceFigures.profitValue)}</b-form-input>
      //                </div>`
      //     }
      //
      //     if (this.purchasePriceSelector.display.discountPrice && offer.purchasePriceFigures.discountPrice > 0) {
      //       result += `<div class="d-flex align-items-center mb-2">
      //                   <p class="col-4 text-left p-0 mb-0 font-weight-600">Disc Price</p>
      //                   <b-form-input disabled class="col-4 p-0 form-control tooltip-each-price-information-input font-weight-normal">${this.formatValue(offer.purchasePriceFigures.discountPrice)}</b-form-input>
      //                 `
      //       if (this.purchasePriceSelector.display.profitValue) {
      //         let discPricePercentage = accounting.toFixed((100 * offer.purchasePriceFigures.discountProfitValue) / offer.purchasePriceFigures.discountPrice, 2)
      //         result += `<p className="col-4 text-left p-0 mb-0 pl-3">${discPricePercentage}%</p>`
      //       }
      //       result += `
      //       </div>`
      //       result += `<div class="d-flex align-items-center mb-2">
      //                   <p class="col-4 text-left p-0 mb-0 font-weight-600">Disc Profit Value</p>
      //                   <b-form-input disabled class="col-4 p-0 form-control tooltip-each-price-information-input font-weight-normal">${this.formatValue(offer.purchasePriceFigures.discountProfitValue)}</b-form-input>
      //                 </div>`
      //
      //     }
      //   }
      //
      //   result += `<div/>`
      //   return result
      // } else {
      //   let stock = _.find(this.stockOptions, (s) => {
      //     return s?.value === offer?.stock
      //   })
      //
      //   let result = `
      //                 <div class="tooltip-each-price-information-block">
      //                 <div class="d-flex">
      //                   <p class="col-4 p-0 text-left font-weight-600">Part Number </p>
      //                   <p class="col-8 text-left p-0">${this.getPartNumber(offer.originalPartId)}</p>
      //                 </div>
      //                 <div class="d-flex">
      //                   <p class="col-4 text-left p-0 font-weight-600">Stock Status </p>
      //                   <p class="col-8 text-left p-0">${stock?.label}</p>
      //                </div>`
      //
      //   if (!_.isEmpty(comment)) {
      //     result += `<div class="d-flex">
      //                   <p class="">${comment}</p>
      //                </div>`
      //   }
      //
      //   result += `  <div class="d-flex align-items-center mb-2">
      //                   <p class="col-4 text-left p-0 mb-0 font-weight-600">List Price</p>
      //                   <b-form-input disabled class="col-8 p-0 form-control tooltip-each-price-information-input font-weight-normal">${this.formatValue(this.getRrp(offer.originalPartId))}</b-form-input>
      //                </div>
      //                 <div class="d-flex align-items-center mb-2">
      //                   <p class="col-4 text-left p-0 mb-0 font-weight-900">Cost Price</p>
      //                   <b-form-input disabled class="col-4 p-0 form-control tooltip-each-price-information-input font-weight-900">${this.formatValue(offer.price)}</b-form-input>
      //                 </div>
      //                 `
      //   if (false && this.getRebate(offer, supplier) > 0) {
      //     result += `     <div class="d-flex align-items-center mb-2">
      //                   <p class="col-4 text-left p-0 mb-0 font-weight-600">Disc Price</p>
      //                   <b-form-input disabled class="col-4 p-0 form-control tooltip-each-price-information-input font-weight-normal">${this.getRebate(offer, supplier)}</b-form-input>
      //                 </div>
      //                <div/>`
      //   }
      //
      //   return result
      // }
    },
    editPart (part) {
      this.$set(part, 'isEdit', true)
    },
    savePart (part) {
      this.$set(part, 'isEdit', false)
      this.$emit('savePart', part)
    },
    removePart (part) {
      if (!confirm('Are you sure?')) {
        return
      }
      let index = _.findIndex(this.supplierPrice.parts, (prt) => {
        return Number(prt.id) === Number(part.id)
      })
      if (index !== -1) {
        this.supplierPrice.parts.splice(index, 1)
      }
      this.$emit('removePart', part)
    },
    isOfferClickable (supplier, part) {
      let offer = supplier.getOffer(part)
      if (offer) {
        if (offer.isNoStock || offer.isNoLongerAvailable || offer.isNotApplicable) {
          return false
        }
        return (offer.isReady || (offer.isNoStock && offer.price > 0))
      }
      return false
      // return supplier.getOffer(part) && supplier.getOffer(part).isReady
    },
    getPartFromSupplier (supplierParts, part) {
      if (part.isManual) {
        return {}
      }
      let find = _.find(supplierParts, { 'originalPartId': Number(part.id) })
      console.log('find', find)
      if (find) {
        return find
      }
      return false
    },
    priceClicked (supplier, part) {
      if (this.isOpenedModal) {
        return
      }
      if (this.isClickedOnIcon) {
        this.isClickedOnIcon = false
        return
      }
      if (!this.isClickable) return
      if (part.isDelivery || (!part.isOriginalPart && this.partIsWithNoCost(part))) {
        return
      }
      if (supplier.rfqToSupplierStatus !== 'Priced' && (this.status === 'Priced' || this.status === 'Ordered' || this.status === 'Cancelled')) {
        return
      }
      let offer = supplier.getOffer(part)
      if (!offer) return
      if (offer.isNoStock || offer.isNoLongerAvailable || offer.isNotApplicable) {
        return
      }
      if (!offer.isReady) {
        if (part.isOriginalPart && !(offer.isNoStock && offer.price > 0)) {
          console.log('Not Ready1')
          return
        } else if (!part.isOriginalPart && !offer.isDelivery) {
          console.log('Not Ready2')
          return
        }
      }
      //commented by #598
      // let isSelected = !!_.find(this.selectedParts, (p) => {
      //   return Number(p.partId) == Number(part.id) && Number(p.suppId) == Number(supplier.id)
      // })
      // if (!isSelected && Number(this.getCostPricePercentageForTooltip(offer, part, supplier)) > Number(this.purchasePriceSelector?.listPricePercents?.markUp) &&
      //     offer?.purchasePriceFigures?.sellPrice !== null && !offer?.purchasePriceFigures?.sellPriceCheck) {
      //   offer.purchasePriceFigures.sellPriceCheck = true
      //   this.onChangedOnCheckbox(1, offer, supplier, part)
      // }
      let offerId = offer.id
      this.supplierPrice.selectOffer(supplier.id, part.id, part, offer)
      console.log('select')
      this.$emit('select', supplier.id, part.id, offerId, part, offer, supplier.type)

      return
    },
    supplierClicked (supplier) {
      if (!this.isClickable || supplier.isRfqToSupplierStatusDecline) return
      if (this.isSelectedAllParts(supplier) || !_.isEmpty(supplier.selectedParts)) {
        this.supplierPrice.unSelectAllPartsFrom(supplier.id)
        this.$emit('unselect')
      } else {
        this.supplierPrice.selectAllPartsFrom(supplier.id)
        this.$emit('select', supplier.id, null, null)
      }
    },
    orderCreate (supp) {
      if (this.isQuoteExpired(supp)) {
        this.$emit('openModal')
      } else {
        this.$emit('click', this.supplierPrice.getSelectedPartsIds())
      }

      // console.log(this.supplierPrice.getSelectedPartsIds());
    },
    getPartLinkedName (part) {
      return this.supplierPrice.getPartLinkedName(part)
    },
    // methods related to Has Link and Linked Parts
    partIsLinked (part) {
      return this.supplierPrice.partIsLinked(part)
    },
    partIsWithNoCost (part) {
      return this.supplierPrice.partIsWithNoCost(part)
    },
    partIsWithCost (part) {
      return this.supplierPrice.partIsWithCost(part)
    },
    getAltPartName (partId) {
      return this.supplierPrice.getAltPartName(partId)
    },
    getPartNameForSubmitted (partId) {
      return this.supplierPrice.getPartNameForSubmitted(partId)
    },
    getDeclineReasonForSubmitted () {
      return this.supplierPrice.getDeclineReasonForSubmitted()
    },
    getPartRrpForSubmitted (partId) {
      return this.supplierPrice.getPartRrpForSubmitted(partId)
    },
    getPartNumberForSubmitted (partId) {
      return this.supplierPrice.getPartNumberForSubmitted(partId)
    },
    getPartNumber (partId) {
      return this.supplierPrice.getPartNumber(partId)
    },
    getPartNumberOfSelectedPart (prtId) {
      return this.supplierPrice.getPartNumberOfSelectedPart(prtId)
    },
    getStockCommentForBackOrEx (id) {
      let r = this.getStockComment(id)
      if (_.isEmpty(r)) {
        if (this.getIsHasExOrBackComment(id).ex) {
          return 'EX Warehouse'
        } else {
          return 'On Back Order'
        }
      }
      return r
    },
    getIsHasExOrBackComment (partId) {
      return this.supplierPrice.getIsHasExOrBackComment(partId)
    },
    getStockComment (partId) {
      let result = _.trim(this.supplierPrice?.getStockComment(partId))?.substr(0, 15)
      if (this.supplierPrice?.getStockComment(partId)?.length > 15) {
        result += '...'
      }
      return result
    },
    getStockCommentFull (partId) {
      return this.supplierPrice?.getStockComment(partId)
    },
    toLower (val) {
      return _.toLower(val)
    },
    handleResize () {
      let parts = _.cloneDeep(this.supplierPrice.parts)
      this.supplierPrice.parts.splice(0, this.supplierPrice.parts.length)
      _.forEach(parts, (p) => {
        this.supplierPrice.parts.push(p)
      })
      this.setLeftPosition()
    },
  },

  mounted () {
    this.isClickable = (this.status === 'Priced')
    window.addEventListener('resize', this.handleResize)
    window.addEventListener('keydown', this.closeAllTooltips)
    document.body.addEventListener('click', this.closeAllTooltipsByClick)
    window.addEventListener('scroll', this.setLeftPosition)
    window.addEventListener('keydown', this.keyDownEvent)
    this.setLeftPosition()
    //if(!this.isClickable) this.supplierPrice.unselectAll();
  },
  beforeDestroy () {
    window.removeEventListener('resize', this.handleResize)
    window.removeEventListener('keydown', this.closeAllTooltips)
    window.removeEventListener('click', this.closeAllTooltipsByClick)
    window.removeEventListener('scroll', this.setLeftPosition)
    window.removeEventListener('keydown', this.keyDownEvent)
  },
  watch: {
    status (newVal) {
      if (newVal === 'Priced') {
        this.isClickable = true
      } else {
        this.isClickable = false
      }
    }
  }

}
</script>

<!--<style lang="scss">-->
<!--  @import "../../../../styles/style-components";-->
<!--</style>-->

<style scoped>
.supplier-icon-ps-tooltip >>> .tooltip-inner{
  border-radius: 10px 10px 10px 0 !important;
}
.supplier-icon-ps-tooltip.bs-tooltip-left >>> .tooltip-inner{
  border-radius: 10px 10px 0 10px !important;
}
.supplier-icon-ps-tooltip.bs-tooltip-right{
  top: -20px !important;
  left: -14px !important;
}
.supplier-icon-ps-tooltip.bs-tooltip-left{
  top: -20px !important;
  left: 18px !important;
}
.supplier-icon .bx{
  font-size: 27px;
  opacity: 1 !important;
}
.dark-grey-or-green-triangle{
  padding: 20px;
  position: relative;
  color: #fff;
}
.dark-grey-triangle-icon {
  position: absolute;
  right: 0;
  top: 0;
  border: 8px solid transparent;
  border-top: 8px solid #A3A3A3FF;
  border-right: 8px solid #A3A3A3FF;
}
.dark-green-triangle-icon {
  position: absolute;
  right: 0;
  top: 0;
  border: 8px solid transparent;
  border-top: 8px solid #1B9C2CFF;
  border-right: 8px solid #1B9C2CFF;
}
.part-list-company-color {
  height: 5px;
  width: 80%;
  background: #083d77;
  border-radius: 5px;
  position: absolute;
  bottom: 0;
  left: 14px;
}

.hidden-vue-dropzone {
  display: none;
}

.import-pdf-loader {
  max-width: 646px;
  min-height: 300px;
  height: calc(100vh - 500px);
  max-height: 600px;
  margin: 20px auto 0px auto;
  border: 2px dashed rgba(27, 30, 56, 0.25);
}

.bxs-file-pdf{
  display: block;
  font-size: 70px;
  color: #1C1F39
}

.bx-cloud-upload{
  font-size: 120px;
  color: #1C1F39
}

.dropify-wrapper {
  height: 98%;
  border: 2px dashed rgba(27, 30, 56, 0.25);
  margin: 5px;
}

.btn-primary{
  background-color: #29BBC1;
  border-color: #29BBC1;
}

.color-green {
  color: #2DCA73 !important;
}

.color-red {
  color: red !important
}

.b-table-highlight-all-sticky.table.b-table > thead > tr > th {
  position: sticky !important;
  cursor: pointer;
}

.orange-icon {
  color: orange;
}

.gray-icon {
  color: rgb(121, 121, 121) !important;
}

.green-icon {
  color: rgb(60, 199, 169) !important;
}

.blue-icon {
  color: #2f6da5 !important;
}

.decline-color {
  color: #1C1F39;
  opacity: 0.5;
  animation-name: flashing;
  animation-timing-function: cubic-bezier(0.03, 0.25, 0.32, 1.28);
  animation-duration: 2s;
  animation-iteration-count: infinite;
}

.error-order {
  padding-right: 6px;
  animation-name: rfq-order-btn-blinked-orange;
  animation-duration: 3s;
  animation-iteration-count: infinite;
}

.error-order-padding {
  padding-right: 6px;
}

@keyframes rfq-order-btn-blinked-orange {
  50% {
    background-color: #F59345;
    border-color: #F59345;
  }
}

.error-icon-flashing {
  animation-name: flashing;
  animation-timing-function: cubic-bezier(0.03, 0.25, 0.32, 1.28);
  animation-duration: 2s;
  animation-iteration-count: infinite;
}

@keyframes flashing {
  50% {
    color: red;
    opacity: 1;
  }
}

.disable-part {
  cursor: default !important;
}

.V3 .rfq-view-page .part-list .disable-part:active {
  background-color: rgb(243, 243, 243) !important;
}

.V3 .rfq-view-page .part-list .selected-disable-part:active {
  background-color: rgba(46, 169, 255, 0.1) !important;
}

.supplier-name {
  position: relative;
  z-index: 100;
  min-height: 54px !important;
}

th.part-list-action {
  padding-top: 26px;
}

.part-list-action i {
  font-size: 20px;
  cursor: pointer;
}

.part-list-action i {
  font-size: 20px;
}

.part-list-price-linked__icon {
  font-size: 18px;
  color: grey;
}

.total-price-number-position {
  text-align: end;
  padding-right: 25px;
}

.part-list-item-price__icon-group-linked {
  flex: 0 1 0px;
  position: relative;
  min-width: 20px;
  width: 20px;
}

.table-b-table-supplier-width {
  min-width: 118px;
}

.green-cell {
  color: #0cb458 !important;
}

.red-cell {
  color: red !important;
}

.part-list-supplier-non-non-active {
  background-color: #e3e4e7 !important;
  cursor: default !important;
}

.part-list-supplier-non-non-active .part-list-item-price__icon-group {
  visibility: hidden !important;
}

.rfq-view-page .bx-error-alt {
  font-size: 16px;
}

.part-list-item-price__value {
  padding: 0 5px !important;
  align-self: center;
}

.part-number-stock-orange {
  color: #F59345 !important;
  font-weight: 600;
  width: auto;
  white-space: nowrap;
  animation: blinking-orange 1s linear infinite;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  animation-delay: 250ms;
}

.part-number-stock-red {
  color: red;
  font-weight: 600;
  width: auto;
  white-space: nowrap;
  animation: blinking-red 1s linear infinite;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  animation-delay: 250ms;
}

.price-red-blinking {
  color: red;
  animation: blinking-gray 1s linear infinite;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  animation-delay: 250ms;
}

@keyframes blinking-gray {
  20% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
  80% {
    opacity: 1;
  }
}

@keyframes blinking-orange {
  20% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  80% {
    opacity: 1;
  }
}

@keyframes blinking-red {
  20% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  80% {
    opacity: 1;
  }
}

.part-number-stock-orange br,
.part-number-stock-red br {
  display: none;
}

.icon-red-blinking {
  color: red;
  animation: blinking-red 1s linear infinite;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  animation-delay: 250ms;
}

.icon-orange-blinking {
  animation: blinking-orange 1s linear infinite;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  animation-delay: 250ms;
}

.part-list-item-price__icon-group-wrapper {
  width: 20px;
  min-width: 20px;
  display: block;
}

.linked-text {
  width: 100%;
  padding: 0 5px;
  text-align: end;
}

.color-gray {
  color: #85868e;
}




.browse-btn-files:hover{
  background-color: #29BBC1;
  border-color: #29BBC1;
}
</style>

<style>
.repairer-import-pfd-pricing-modal .dz-preview {
  opacity: 0;
}
.two-icons-group {
  display: flex !important;
  min-width: 30px !important;
}

.icon-with-text {
  height: 60px !important;
  padding-top: 17px !important;
}

.transparent-icon {
  cursor: default !important;
  color: transparent !important;
}

.fixed-position-markup-and-price {
  transform: translateX(-8px);
}

.tooltip-purchase-checkbox .icon {
  border-color: #d3d3d3 !important;
}

.tooltip-purchase-checkbox > input[type="checkbox"]:checked + span.icon {
  background-color: #79B1BB !important;
  border-color: #79B1BB !important;
}

.tooltip-purchase-checkbox > input[type="checkbox"]:checked + span.icon i {
  color: white !important;
}

#rfq-view-line-column,
#rfq-view-part-list-column,
#rfq-view-part-number-column,
#rfq-view-qty-column,
#rfq-view-rrp-column,
.rfq-view-line-cell,
.rfq-view-part-list-cell,
.rfq-view-part-number-cell,
.rfq-view-qty-cell,
.rfq-view-rrp-cell,
.info-line {
  position: sticky !important;
  z-index: 5;
}

.rfq-view-part-line:hover .rfq-view-line-cell,
.rfq-view-part-line:hover .rfq-view-part-list-cell,
.rfq-view-part-line:hover .rfq-view-part-number-cell,
.rfq-view-part-line:hover .rfq-view-rrp-cell,
.rfq-view-part-line:hover .rfq-view-qty-cell {
  background-color: #e9f8f9 !important;
}

.repairer-rfq-view-part-list-table-container-2 tr:hover .info-line {
  background-color: #e9f8f9 !important;
}

#rfq-view-line-column,
#rfq-view-part-list-column,
#rfq-view-part-number-column,
#rfq-view-qty-column,
#rfq-view-rrp-column {
  background-color: rgb(227, 228, 231) !important
}

.rfq-view-line-cell,
.rfq-view-part-list-cell,
.rfq-view-part-number-cell,
.rfq-view-qty-cell,
.rfq-view-rrp-cell,
.info-line {
  background-color: white !important
}

.info-line {
  opacity: 1 !important;
  color: rgb(173, 174, 183) !important;
}

.blinking-green {
  animation-name: blinking-green;
  animation-duration: 4s;
  animation-iteration-count: infinite;
  animation-delay: 250ms;
  cursor: pointer;
}

@keyframes blinking-green {
  20% {
    color: #0cb458;
    opacity: 1;
  }
  80% {
    color: #0cb458;
    opacity: 1;
  }
}

.custom-tooltip-calculate-information {
  opacity: 1 !important;
}

.custom-tooltip-calculate-information .tooltip-each-price-information-block {
  position: absolute;
  width: 347px;
  padding: 20px 20px 20px 0px;
  top: -21px;
  left: -6px;
}

.custom-tooltip-calculate-information .tooltip-each-price-information-block-last {
  left: 61px;
}

.nr-custom-tooltip-calculate-information .tooltip-each-price-information-block {
  padding: 0px 20px 20px 0px;
  top: -1px;
}

.nr-custom-tooltip-calculate-information .tooltip-each-price-information-block-last {
  left: 50px !important;
}

.custom-tooltip-calculate-information .tooltip-inner {
  background-color: transparent !important;
  box-shadow: none !important;
}

.custom-tooltip-calculate-information .tooltip-each-price-information-block-inner {
  width: 325px;
  padding: 10px;
  background-color: #f2f2f2;
  border-radius: 3px;
  border: 1px solid rgba(27, 30, 56, 0.25);
  font-size: 11px;
}

.revert-custom-tooltip-position .tooltip-each-price-information-block {
  left: -306px !important;
  top: -14px !important;
}

.revert-custom-tooltip-position-2 .tooltip-each-price-information-block {
  left: -324px !important;
  top: -14px !important;
}

.revert-custom-tooltip-position-2 .tooltip-each-price-information-block,
.revert-custom-tooltip-position .tooltip-each-price-information-block {
  padding: 20px 0px 20px 20px !important;
}

.revert-custom-tooltip-position .tooltip-inner {
  background-color: transparent !important;
  box-shadow: none !important;
}

.custom-tooltip-calculate-information .tooltip-each-price-information-input {
  max-width: 80px;
  min-width: 80px;
  height: 25px;
  padding-top: 3px !important;
}

.custom-tooltip-calculate-information .tooltip-each-price-information-line {
  border-bottom: 3px solid rgba(27, 30, 56, 0.22) !important;
}

.custom-tooltip-calculate-information .tooltip-each-price-information-block .font-weight-600 {
  font-weight: 600;
}

.custom-tooltip-calculate-information .tooltip-each-price-information-block .font-weight-900 {
  font-weight: 900;
}

.tooltip-checkbox label.checkbox > span.icon {
  width: 20px;
  height: 20px;
}

.tooltip-checkbox label.checkbox > span.icon i.bx {
  font-size: 19px;
}

.tooltip .width-3-markup-and-price {
  max-width: 80px !important;
  width: 80px !important;
}

</style>
